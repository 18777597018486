export const VALIDGUESSES = [
'abaca',
'abacă',
'abace',
'abale',
'abată',
'abate',
'abați',
'abaua',
'abătu',
'abces',
'abdic',
'abila',
'abilă',
'abile',
'abili',
'abjur',
'abneg',
'aboli',
'abona',
'abonă',
'abrac',
'abraș',
'abrog',
'abție',
'abții',
'abțin',
'abțiu',
'aburc',
'aburi',
'abuza',
'abuză',
'acaju',
'acana',
'acant',
'acare',
'acari',
'acasă',
'acață',
'acațe',
'acați',
'acăța',
'acăță',
'acățe',
'acăți',
'acâni',
'acced',
'acces',
'acciz',
'aceea',
'aceia',
'acela',
'acele',
'acera',
'aceră',
'acerb',
'acere',
'aceri',
'acest',
'achii',
'achit',
'achiu',
'acida',
'acidă',
'acide',
'acila',
'acilă',
'acili',
'acine',
'acira',
'aciră',
'acire',
'aciri',
'aciua',
'aciuă',
'aciui',
'acizi',
'aclam',
'acnee',
'acolo',
'acont',
'acord',
'acqua',
'acrea',
'acrei',
'acria',
'acrie',
'acrii',
'acrim',
'acriș',
'acrit',
'acriu',
'acrul',
'acruț',
'activ',
'actor',
'actul',
'acuma',
'acușa',
'acuși',
'acuta',
'acută',
'acute',
'acuți',
'acuza',
'acuză',
'acuze',
'acuzi',
'adaog',
'adaos',
'adapă',
'adape',
'adapi',
'adast',
'adaug',
'adaus',
'adăpa',
'adăpă',
'adăpe',
'adăpi',
'adăst',
'adânc',
'adecă',
'adept',
'adera',
'aderă',
'adere',
'aderi',
'adese',
'adiai',
'adiam',
'adiat',
'adiau',
'adică',
'adiem',
'adins',
'admir',
'admis',
'admit',
'adnat',
'adopt',
'adora',
'adoră',
'adore',
'adori',
'adorm',
'aducă',
'aduce',
'aduci',
'aduct',
'adula',
'adulă',
'adult',
'aduna',
'adună',
'adune',
'aduni',
'adusa',
'adusă',
'aduse',
'aduși',
'advon',
'aedul',
'aequo',
'aerai',
'aeraj',
'aeram',
'aerat',
'aerau',
'aerăm',
'aerez',
'aerob',
'aeros',
'aerul',
'aezii',
'afară',
'afâna',
'afână',
'afect',
'afgan',
'afida',
'afidă',
'afide',
'afifa',
'afifă',
'afife',
'afifi',
'afina',
'afină',
'afine',
'afini',
'afion',
'afipt',
'afirm',
'afișa',
'afișă',
'afișe',
'afixe',
'aflai',
'aflam',
'aflat',
'aflau',
'aflăm',
'aflui',
'aflux',
'afona',
'afonă',
'afone',
'afoni',
'aftei',
'aftor',
'aftos',
'afuma',
'afumă',
'afume',
'afumi',
'afund',
'afurc',
'agale',
'agapa',
'agapă',
'agape',
'agasa',
'agasă',
'agata',
'agată',
'agate',
'agață',
'agațe',
'agaua',
'agava',
'agavă',
'agave',
'agăța',
'agăță',
'agățe',
'agăți',
'agent',
'agera',
'ageră',
'agere',
'ageri',
'agest',
'aghii',
'agiei',
'agila',
'agilă',
'agile',
'agili',
'agita',
'agită',
'agite',
'agiți',
'agiul',
'agnat',
'agneț',
'agona',
'agonă',
'agone',
'agora',
'agoră',
'agore',
'agrar',
'agrăi',
'agrea',
'agreă',
'agriș',
'aguda',
'agudă',
'agude',
'aguzi',
'aheii',
'aheul',
'ahtia',
'ahtie',
'ahtul',
'ahuri',
'aicea',
'aidam',
'aidem',
'aiept',
'aiest',
'aieve',
'ailor',
'aiori',
'aista',
'aistă',
'aiste',
'aișor',
'aiști',
'aiura',
'aiură',
'aiure',
'aiuri',
'ajuna',
'ajună',
'ajung',
'ajuns',
'ajura',
'ajură',
'ajuta',
'ajută',
'ajute',
'ajuți',
'akâni',
'alace',
'alait',
'alama',
'alamă',
'alame',
'alana',
'alană',
'alane',
'alani',
'alaun',
'alămi',
'albea',
'albei',
'albeț',
'albia',
'albie',
'albii',
'albim',
'albit',
'albiu',
'albui',
'albul',
'album',
'albuș',
'albuț',
'alcan',
'alcov',
'aldan',
'aldin',
'aldol',
'aldui',
'alean',
'aleea',
'alega',
'alegă',
'alege',
'alegi',
'aleii',
'alela',
'alelă',
'alele',
'alena',
'alenă',
'alene',
'alerg',
'alert',
'alese',
'aleși',
'aleza',
'aleză',
'aleze',
'algei',
'algia',
'algid',
'algie',
'algii',
'algol',
'aliai',
'aliaj',
'aliam',
'alias',
'aliat',
'aliau',
'alibi',
'alica',
'alică',
'alice',
'alici',
'aliem',
'aliez',
'alina',
'alină',
'aline',
'alini',
'alint',
'alior',
'alipi',
'almar',
'almee',
'almei',
'aloca',
'alocă',
'aloce',
'aloci',
'aloea',
'aloei',
'aloul',
'alpin',
'altar',
'altei',
'alter',
'altoi',
'altor',
'altui',
'altul',
'alții',
'aluat',
'aluna',
'alună',
'alune',
'alung',
'aluni',
'alura',
'alură',
'aluri',
'amant',
'amara',
'amară',
'amare',
'amari',
'amăgi',
'amăra',
'amărî',
'amâna',
'amână',
'amâne',
'amâni',
'ambar',
'ambii',
'ambra',
'ambră',
'ambre',
'ament',
'ameți',
'amhar',
'amiaz',
'amiba',
'amibă',
'amibe',
'amica',
'amică',
'amice',
'amici',
'amida',
'amidă',
'amide',
'amiez',
'amili',
'amina',
'amină',
'amine',
'amino',
'amnar',
'amorf',
'amper',
'ampex',
'ampla',
'amplă',
'ample',
'ampli',
'amplu',
'amurg',
'amușă',
'amușe',
'amuși',
'amuța',
'amuță',
'amuțe',
'amuți',
'amuza',
'amuză',
'amuze',
'amuzi',
'amvon',
'anala',
'anală',
'anale',
'anali',
'anaua',
'ancia',
'ancie',
'ancii',
'andin',
'anexa',
'anexă',
'anexe',
'angli',
'angor',
'angro',
'anima',
'animă',
'anime',
'animi',
'anina',
'anină',
'anine',
'anini',
'anion',
'anost',
'anozi',
'ansei',
'anșoa',
'antal',
'antei',
'antet',
'antic',
'antum',
'anual',
'anuar',
'anula',
'anulă',
'anume',
'anunț',
'anura',
'anură',
'anure',
'aoleo',
'aoleu',
'aorta',
'aortă',
'aorte',
'apară',
'apare',
'apari',
'apasă',
'apași',
'apăra',
'apără',
'apăru',
'apăsa',
'apăsă',
'apela',
'apelă',
'apele',
'apere',
'aperi',
'apese',
'apeși',
'apida',
'apidă',
'apide',
'apiol',
'aplec',
'aplic',
'aplit',
'apnee',
'apoda',
'apodă',
'apode',
'apolo',
'aport',
'apoși',
'apozi',
'apret',
'aprig',
'april',
'aprob',
'aprod',
'aptei',
'apter',
'aptul',
'apții',
'apuca',
'apucă',
'apuce',
'apuci',
'apuie',
'apuli',
'apună',
'apune',
'apupa',
'apupă',
'apupe',
'apusa',
'apusă',
'apuse',
'apuși',
'araba',
'arabă',
'arabe',
'arabi',
'araci',
'arama',
'aramă',
'arame',
'arapi',
'arară',
'arare',
'arase',
'arași',
'arata',
'arată',
'arate',
'arați',
'arăci',
'arămi',
'arări',
'arăta',
'arătă',
'arăți',
'arând',
'arbie',
'arbiu',
'arbor',
'arbuz',
'arcan',
'arcaș',
'arcat',
'arcei',
'arcer',
'arcui',
'arcul',
'arcuș',
'ardea',
'ardei',
'ardem',
'ardic',
'ardil',
'areal',
'areci',
'areic',
'arena',
'arenă',
'arene',
'arest',
'areta',
'aretă',
'arete',
'areți',
'arfei',
'argat',
'argea',
'argon',
'argos',
'argou',
'argus',
'arhar',
'arhon',
'arian',
'arici',
'arida',
'aridă',
'aride',
'ariei',
'arile',
'arili',
'arima',
'arimă',
'arina',
'arină',
'arine',
'arini',
'aripa',
'aripă',
'aripi',
'arizi',
'armai',
'armam',
'arman',
'armar',
'armaș',
'armat',
'armau',
'armăm',
'armân',
'armei',
'armez',
'armia',
'armie',
'armii',
'armul',
'arneu',
'aroga',
'arogă',
'aroge',
'arogi',
'aroma',
'aromă',
'arome',
'aromi',
'arpei',
'arsei',
'arsen',
'arsul',
'arșic',
'arșii',
'arșin',
'artei',
'artel',
'artos',
'arțag',
'arțar',
'arunc',
'arzoi',
'arzos',
'arzul',
'asabi',
'asalt',
'asana',
'asană',
'asară',
'ascei',
'ascet',
'ascut',
'aseca',
'asecă',
'asece',
'aseci',
'asiat',
'asini',
'asist',
'asiza',
'asiză',
'asize',
'asmut',
'asmuț',
'aspic',
'aspir',
'aspra',
'aspră',
'aspre',
'aspri',
'aspru',
'assai',
'astea',
'astei',
'astma',
'astmă',
'astme',
'astre',
'astru',
'astup',
'asuda',
'asudă',
'asude',
'asuma',
'asumă',
'asume',
'asumi',
'asuzi',
'așași',
'așază',
'așeza',
'așeză',
'așeze',
'așezi',
'aștri',
'ataca',
'atacă',
'atace',
'ataci',
'atale',
'atare',
'atari',
'atașa',
'atașă',
'atașe',
'ataua',
'atârn',
'atâta',
'atâți',
'ateea',
'ateei',
'ateii',
'atela',
'atelă',
'atele',
'atent',
'atest',
'ateul',
'atica',
'atică',
'atice',
'atici',
'ating',
'atins',
'atlas',
'atlaz',
'atlet',
'atoli',
'atoma',
'atomă',
'atome',
'atomi',
'atona',
'atonă',
'atone',
'atoni',
'atrag',
'atras',
'atrii',
'atriu',
'atuul',
'ațâța',
'ațâță',
'ațâțe',
'ațâți',
'ațele',
'ațica',
'ațică',
'ațice',
'ațici',
'ațină',
'aține',
'aținu',
'ațipi',
'ațița',
'ațiță',
'ațițe',
'ațiți',
'ațoși',
'audia',
'audie',
'audio',
'audit',
'augur',
'auiai',
'auiam',
'auiau',
'auind',
'auiră',
'auire',
'auiri',
'auise',
'auiși',
'auiți',
'aulei',
'auleu',
'aulic',
'aulul',
'aurar',
'aurea',
'aurei',
'aurel',
'auria',
'aurie',
'aurii',
'aurim',
'aurit',
'auriu',
'auros',
'aurul',
'aușei',
'aușel',
'autor',
'autul',
'auzea',
'auzii',
'auzim',
'auzit',
'auzul',
'avaet',
'avama',
'avamă',
'avame',
'avami',
'avana',
'avană',
'avane',
'avani',
'avans',
'avara',
'avară',
'avare',
'avari',
'avați',
'având',
'avânt',
'aveai',
'aveam',
'aveau',
'avele',
'avena',
'avenă',
'avene',
'avere',
'averi',
'avers',
'aveți',
'aviar',
'avida',
'avidă',
'avide',
'avion',
'aviva',
'avivă',
'aviza',
'aviză',
'avize',
'avizi',
'avizo',
'avlia',
'avlie',
'avlii',
'avort',
'avram',
'avură',
'avuse',
'avuși',
'avuta',
'avută',
'avute',
'avuți',
'axară',
'axare',
'axase',
'axași',
'axata',
'axată',
'axate',
'axați',
'axări',
'axând',
'axele',
'axeze',
'axezi',
'axial',
'axila',
'axilă',
'axile',
'axion',
'axoni',
'axuri',
'azera',
'azeră',
'azere',
'azeri',
'azida',
'azidă',
'azide',
'azima',
'azimă',
'azime',
'azoic',
'aztec',
'azura',
'azură',
'azuri',
'ăleia',
'ălora',
'ăluia',
'ăstei',
'ăstor',
'ăstui',
'ăștia',
'ăuiai',
'ăuiam',
'ăuiau',
'ăuind',
'ăuiră',
'ăuire',
'ăuiri',
'ăuise',
'ăuiși',
'ăuiți',
'babac',
'baban',
'babau',
'babei',
'baboi',
'bacal',
'bacău',
'bacei',
'bacii',
'bacil',
'bacon',
'bacul',
'badea',
'badei',
'bafta',
'baftă',
'bafte',
'bagaj',
'bagăm',
'bagea',
'bahic',
'bahna',
'bahnă',
'bahne',
'bahți',
'baian',
'baier',
'baiți',
'baiul',
'balaș',
'balâc',
'balei',
'balet',
'balia',
'balic',
'balie',
'balii',
'balon',
'balot',
'balsa',
'balse',
'balta',
'baltă',
'balul',
'bamei',
'banal',
'banan',
'banat',
'banca',
'bancă',
'banco',
'banda',
'bandă',
'bande',
'banei',
'banii',
'banjo',
'banta',
'bantă',
'bantu',
'banul',
'barai',
'baraj',
'baram',
'barat',
'barau',
'barăm',
'barba',
'barbă',
'barca',
'barcă',
'barda',
'bardă',
'barei',
'barej',
'barem',
'barez',
'baria',
'baric',
'barie',
'barii',
'baril',
'barim',
'bariș',
'bariu',
'bariz',
'barja',
'barjă',
'barje',
'barni',
'baroc',
'baron',
'baros',
'barou',
'barul',
'barza',
'barză',
'barzi',
'basca',
'bască',
'basce',
'basci',
'baset',
'basic',
'basma',
'basme',
'basna',
'basnă',
'basne',
'bason',
'basta',
'basul',
'bașca',
'bașcă',
'bașii',
'bașta',
'baștă',
'baște',
'bașul',
'bataj',
'batal',
'batat',
'batav',
'batăr',
'batâr',
'batca',
'batcă',
'batce',
'batel',
'batem',
'batic',
'batir',
'batiu',
'batoc',
'batog',
'baton',
'bauzi',
'bavei',
'baxul',
'bazai',
'bazal',
'bazam',
'bazar',
'bazat',
'bazau',
'bazăm',
'bazei',
'bazez',
'bazic',
'bazin',
'bazon',
'băbar',
'băboi',
'băcan',
'băcit',
'bădia',
'bădic',
'bădie',
'bădii',
'băgai',
'băgam',
'băgat',
'băgau',
'băgăm',
'băiai',
'băiam',
'băiaș',
'băiat',
'băiau',
'băieș',
'băiet',
'băile',
'băind',
'băiră',
'băire',
'băiri',
'băise',
'băiși',
'băița',
'băiță',
'băițe',
'băiți',
'bălai',
'bălan',
'bălei',
'bălii',
'bălos',
'bălti',
'bălța',
'bălță',
'bălți',
'bălul',
'băluț',
'bănat',
'bănci',
'bănea',
'bănet',
'bănia',
'bănie',
'bănii',
'bănim',
'bănit',
'bănos',
'bănui',
'bănuț',
'bărat',
'băraț',
'bărbi',
'bărci',
'bărzi',
'băsma',
'băsni',
'bășic',
'băști',
'bătăi',
'bătea',
'bătui',
'bătut',
'bățos',
'bățul',
'băune',
'băuni',
'băură',
'băuse',
'băuși',
'băuta',
'băută',
'băute',
'băuți',
'bâcâi',
'bâhla',
'bâhlă',
'bâhle',
'bâhli',
'bâlci',
'bârâi',
'bârfa',
'bârfă',
'bârfe',
'bârfi',
'bârna',
'bârnă',
'bârne',
'bârsa',
'bârsă',
'bârse',
'bâtca',
'bâtcă',
'bâtci',
'bâtei',
'bâtul',
'bâțan',
'bâțâi',
'bâții',
'bâzâi',
'bâzei',
'bâzoi',
'bâzzz',
'beata',
'beată',
'beați',
'becar',
'becer',
'bechi',
'becii',
'becul',
'bedei',
'behăi',
'beica',
'beică',
'beici',
'beiul',
'bejul',
'belea',
'belgi',
'belii',
'belim',
'beliș',
'belit',
'belul',
'bemol',
'benei',
'benga',
'beniș',
'bente',
'benți',
'benzi',
'berar',
'berat',
'berce',
'berci',
'berea',
'beret',
'berii',
'beril',
'berma',
'bermă',
'berme',
'berna',
'bernă',
'berne',
'berta',
'bertă',
'berte',
'berze',
'bessi',
'beșic',
'betei',
'betel',
'beton',
'beția',
'beție',
'beții',
'bețiv',
'beuca',
'beucă',
'beuci',
'beuța',
'beuță',
'beuțe',
'bezea',
'bezna',
'beznă',
'bezne',
'biata',
'biată',
'biban',
'biber',
'bibic',
'bibil',
'bicaș',
'bideu',
'bidon',
'biela',
'bielă',
'biele',
'biete',
'bieți',
'bieul',
'bifai',
'bifam',
'bifat',
'bifau',
'bifăm',
'bifei',
'bifez',
'bifid',
'bifor',
'bigam',
'bigei',
'bigii',
'bigot',
'bigul',
'bihun',
'bijoi',
'biker',
'bilei',
'bilet',
'bilon',
'biman',
'binar',
'biniș',
'binom',
'binta',
'bintă',
'binte',
'bioți',
'biped',
'bipul',
'birar',
'birăi',
'birău',
'biriș',
'birja',
'birjă',
'birje',
'birou',
'birui',
'birul',
'bisai',
'bisam',
'bisat',
'bisau',
'bisăm',
'bisel',
'bisez',
'bisul',
'biter',
'bitul',
'bitum',
'biței',
'biții',
'biuta',
'biută',
'biute',
'bivol',
'bizam',
'bizar',
'bizeț',
'bizon',
'bizui',
'blaga',
'blagă',
'blama',
'blamă',
'blana',
'blană',
'blanc',
'blaza',
'blază',
'blaze',
'blăgi',
'blăni',
'blând',
'bleah',
'bleau',
'bleav',
'blege',
'blegi',
'bleot',
'blide',
'blimp',
'blitz',
'bloca',
'blocă',
'blond',
'bluca',
'blucă',
'bluci',
'blues',
'bluff',
'blugi',
'bluza',
'bluză',
'bluze',
'boaba',
'boabă',
'boabe',
'boacă',
'boala',
'boală',
'boale',
'board',
'boare',
'boari',
'boașe',
'boața',
'boață',
'boațe',
'bobea',
'bober',
'bobii',
'bobim',
'bobit',
'boboc',
'bobot',
'bobul',
'bocal',
'bocăn',
'bocea',
'bocet',
'bocii',
'bocim',
'bocit',
'bocnă',
'bocșa',
'bocșă',
'bocșe',
'bocși',
'bocul',
'boema',
'boemă',
'boeme',
'boemi',
'boeuf',
'bogat',
'bogza',
'bogză',
'bogze',
'boiai',
'boiam',
'boiau',
'boier',
'boind',
'boiră',
'boire',
'boiri',
'boise',
'boiși',
'boita',
'boită',
'boite',
'boiți',
'boiul',
'bojii',
'bojoc',
'bojog',
'boldi',
'boldo',
'bolea',
'bolfa',
'bolfă',
'bolfe',
'bolid',
'bolii',
'bolim',
'bolit',
'bolta',
'boltă',
'bolti',
'bolți',
'bolul',
'bolus',
'bomba',
'bombă',
'bombe',
'bonca',
'bonci',
'bonei',
'bonom',
'bonti',
'bonți',
'bonul',
'bonus',
'bonzi',
'borai',
'boram',
'boran',
'borat',
'borau',
'borax',
'borăi',
'borâi',
'borâm',
'borât',
'bordo',
'boric',
'borii',
'borna',
'bornă',
'borne',
'borod',
'borși',
'borta',
'bortă',
'borte',
'borti',
'borul',
'borza',
'borză',
'borze',
'bosaj',
'bosei',
'bosma',
'boson',
'bosul',
'boșar',
'boșii',
'boșul',
'botău',
'botca',
'botcă',
'botce',
'botei',
'botez',
'botos',
'botul',
'boțea',
'boții',
'boțim',
'boțit',
'boțog',
'boțul',
'bouar',
'bouri',
'bouți',
'bovid',
'bovin',
'boxai',
'boxam',
'boxat',
'boxau',
'boxăm',
'boxei',
'boxer',
'boxez',
'boxul',
'boyul',
'bozia',
'bozie',
'bozii',
'bozul',
'braca',
'bracă',
'braci',
'bradt',
'braga',
'bragă',
'braha',
'brahă',
'brahe',
'brain',
'brama',
'bramă',
'brame',
'brand',
'branț',
'brațe',
'brava',
'bravă',
'brave',
'bravi',
'bravo',
'brazi',
'brăgi',
'brâie',
'brâna',
'brână',
'brâne',
'brâul',
'break',
'breaz',
'brebi',
'breii',
'breji',
'breșa',
'breșă',
'breșe',
'breva',
'brevă',
'breve',
'breza',
'breză',
'breze',
'briai',
'briam',
'briat',
'briau',
'brice',
'brici',
'brida',
'bridă',
'bride',
'briei',
'briem',
'briez',
'brind',
'briza',
'briză',
'brize',
'broda',
'brodă',
'brodi',
'bronz',
'broșa',
'broșă',
'broșe',
'bruft',
'bruia',
'bruie',
'bruma',
'brumă',
'brume',
'bruna',
'brună',
'brune',
'bruni',
'brusc',
'bruși',
'bruta',
'brută',
'brute',
'bruto',
'bruți',
'bubal',
'bubat',
'bubei',
'buboi',
'bubon',
'bubos',
'bubui',
'bucal',
'bucar',
'bucea',
'buche',
'buchi',
'bucii',
'bucin',
'bucla',
'buclă',
'bucle',
'bucșa',
'bucșă',
'bucșe',
'bucși',
'bucul',
'bucur',
'budac',
'budai',
'budam',
'budat',
'budau',
'budăi',
'budăm',
'budei',
'budez',
'budui',
'budur',
'bufan',
'bufei',
'bufet',
'bufeu',
'bufii',
'bufna',
'bufnă',
'bufne',
'bufni',
'bufon',
'buful',
'buged',
'buget',
'bugla',
'buglă',
'bugle',
'buhai',
'buhaș',
'buhav',
'buhăi',
'buhei',
'buhna',
'buhnă',
'buhne',
'buhos',
'buhul',
'buhur',
'buiac',
'buiai',
'buiam',
'buiau',
'buici',
'buind',
'buiră',
'buire',
'buiri',
'buise',
'buiși',
'buiți',
'bujda',
'bujdă',
'bujde',
'bujdi',
'bujia',
'bujie',
'bujii',
'bujor',
'bulat',
'bulbi',
'bulca',
'bulcă',
'bulci',
'bulei',
'bulet',
'bulin',
'bulon',
'buluc',
'bulzi',
'bumbi',
'bumul',
'bunda',
'bundă',
'bunde',
'bunei',
'bunel',
'bunic',
'bunii',
'bunul',
'bunuț',
'burai',
'buraj',
'buram',
'burat',
'burau',
'burăm',
'burba',
'burbă',
'burbe',
'burca',
'burcă',
'burci',
'burei',
'buret',
'burez',
'buric',
'burie',
'burii',
'buriu',
'bursa',
'bursă',
'burse',
'burta',
'burtă',
'burți',
'burul',
'busta',
'bușea',
'bușei',
'bușel',
'bușeu',
'bușii',
'bușim',
'bușit',
'bușon',
'bușul',
'butac',
'butan',
'butar',
'butaș',
'butca',
'butcă',
'butci',
'butea',
'butia',
'butic',
'butie',
'butii',
'butil',
'butoi',
'buton',
'butuc',
'butul',
'butur',
'buții',
'buzat',
'buzăr',
'buzău',
'buzei',
'buzer',
'buzii',
'buzna',
'buzni',
'buzoi',
'bytul',
'byții',
'cabaz',
'cabla',
'cablă',
'cablu',
'cabra',
'cabră',
'cabul',
'cacao',
'cacic',
'cacom',
'cadet',
'cadii',
'cadiu',
'cadou',
'cadra',
'cadră',
'cadre',
'cadru',
'caduc',
'cadus',
'cafas',
'cafea',
'cafer',
'cafri',
'cafru',
'cafti',
'cahla',
'cahlă',
'cahle',
'caiac',
'caice',
'caier',
'caiet',
'caisa',
'caisă',
'caise',
'caiși',
'caizi',
'calai',
'calaj',
'calam',
'calao',
'calat',
'calau',
'calăm',
'calcă',
'calce',
'calci',
'calda',
'caldă',
'calde',
'calea',
'calei',
'calem',
'calez',
'calfa',
'calfă',
'calfe',
'calic',
'calif',
'calin',
'calit',
'calma',
'calmă',
'calme',
'calmi',
'calos',
'calpa',
'calpă',
'calpe',
'calpi',
'calul',
'calup',
'calus',
'calzi',
'camee',
'camei',
'campa',
'campă',
'canaf',
'canal',
'canar',
'canat',
'canci',
'canea',
'canei',
'cange',
'canid',
'canin',
'caniș',
'canoe',
'canon',
'canto',
'capac',
'capan',
'capăt',
'capei',
'caper',
'capeș',
'capia',
'capie',
'capii',
'capiu',
'capoc',
'capon',
'capot',
'capra',
'capră',
'capre',
'capsa',
'capsă',
'capse',
'capta',
'captă',
'capul',
'carab',
'caras',
'carat',
'carda',
'cardă',
'carde',
'carea',
'caret',
'careu',
'caria',
'carib',
'caric',
'carie',
'carii',
'carne',
'carou',
'carpa',
'carpă',
'carpe',
'carpi',
'carst',
'carta',
'cartă',
'carte',
'carub',
'carul',
'carus',
'casai',
'casam',
'casap',
'casat',
'casau',
'casăm',
'casca',
'cască',
'casei',
'casez',
'casii',
'casiu',
'casta',
'castă',
'caste',
'cașei',
'cașet',
'cașeu',
'cașii',
'caște',
'caști',
'cașul',
'catar',
'catâr',
'catch',
'catod',
'catul',
'cațăr',
'caței',
'cauce',
'cauei',
'cauni',
'causa',
'caută',
'caute',
'cauți',
'cauza',
'cauză',
'cauze',
'cavaf',
'caval',
'cavas',
'cavei',
'cavii',
'cavou',
'cavul',
'cazac',
'cazai',
'cazam',
'cazan',
'cazat',
'cazau',
'cazăm',
'cazez',
'cazic',
'cazma',
'cazna',
'caznă',
'cazne',
'cazon',
'cazul',
'cădea',
'cădem',
'cădii',
'cădim',
'cădit',
'căiai',
'căiam',
'căiau',
'căile',
'căina',
'căină',
'căind',
'căini',
'căiră',
'căire',
'căiri',
'căise',
'căiși',
'căita',
'căită',
'căite',
'căița',
'căiță',
'căițe',
'căiți',
'călăi',
'călău',
'călâi',
'călâu',
'călca',
'călcă',
'călci',
'călea',
'călii',
'călim',
'călin',
'călit',
'căluș',
'căluț',
'cămin',
'cănea',
'căngi',
'cănii',
'cănim',
'cănit',
'căpăi',
'căpău',
'căpia',
'căpie',
'căpos',
'căpui',
'căpuș',
'cărai',
'căram',
'cărat',
'cărau',
'cărăm',
'cărei',
'cărni',
'căror',
'cărți',
'cărui',
'căruț',
'căsca',
'căscă',
'căsoi',
'cășăi',
'căști',
'cășuț',
'cătai',
'cătam',
'cătat',
'cătau',
'cătăm',
'cătră',
'către',
'cătun',
'cătur',
'cățăi',
'cățea',
'căței',
'cățel',
'căția',
'căție',
'cății',
'cățui',
'căula',
'căulă',
'căule',
'căușe',
'căuta',
'căută',
'căzii',
'căzni',
'căzui',
'căzut',
'câine',
'câini',
'câlți',
'câmpi',
'câner',
'cânii',
'cânta',
'cântă',
'cânte',
'cânți',
'cârâi',
'cârcă',
'cârja',
'cârjă',
'cârje',
'cârji',
'cârma',
'cârmă',
'cârme',
'cârmi',
'cârna',
'cârnă',
'cârne',
'cârni',
'cârpa',
'cârpă',
'cârpe',
'cârpi',
'cârti',
'câșei',
'câșii',
'câșla',
'câșlă',
'câșle',
'câști',
'câșul',
'câtea',
'câtor',
'câtul',
'câtva',
'ceafa',
'ceafă',
'ceair',
'ceala',
'ceapa',
'ceapă',
'ceara',
'ceară',
'ceata',
'ceată',
'ceața',
'ceață',
'ceaun',
'ceauș',
'cecal',
'cecen',
'cecul',
'cecum',
'cedai',
'cedam',
'cedat',
'cedau',
'cedăm',
'cedez',
'cedri',
'cedru',
'cefar',
'cefei',
'cegăi',
'cegii',
'cehăi',
'cehei',
'cehii',
'cehul',
'celar',
'celea',
'celei',
'cella',
'celle',
'cello',
'celom',
'celor',
'celta',
'celtă',
'celte',
'celți',
'celui',
'centi',
'cenți',
'cenur',
'cepăi',
'cepci',
'cepei',
'cepse',
'cepțe',
'cepui',
'cepul',
'cerat',
'cerbi',
'cerca',
'cercă',
'cerce',
'cerci',
'cerea',
'cerem',
'cerga',
'cergă',
'cergi',
'cerii',
'cerit',
'ceriu',
'cerne',
'cerni',
'cernu',
'ceros',
'cerși',
'certa',
'certă',
'certe',
'cerți',
'cerui',
'cerul',
'cerut',
'cervu',
'cesiu',
'cesta',
'ceste',
'cești',
'cetaș',
'cetea',
'cetei',
'ceter',
'ceteț',
'cetii',
'cetim',
'cetit',
'ceții',
'cețos',
'ceuca',
'ceucă',
'ceuce',
'ceuci',
'cezar',
'cheag',
'cheia',
'cheie',
'cheii',
'chele',
'cheli',
'chema',
'chemă',
'cheme',
'chemi',
'chera',
'chere',
'cheta',
'chetă',
'chete',
'cheul',
'chiar',
'chiau',
'chibz',
'chica',
'chică',
'chici',
'chida',
'chidă',
'chide',
'chila',
'chilă',
'chile',
'chior',
'chiot',
'chips',
'chira',
'chire',
'chist',
'chita',
'chită',
'chite',
'chiti',
'chiți',
'chiui',
'chiul',
'chiup',
'chulo',
'cicar',
'cicli',
'ciclu',
'cidru',
'cifra',
'cifră',
'cifre',
'cifru',
'cigei',
'cihăi',
'cilen',
'cilii',
'cilul',
'cimei',
'cimen',
'cinai',
'cinam',
'cinaș',
'cinat',
'cinau',
'cinăm',
'cinci',
'cinei',
'cinel',
'cinez',
'cinic',
'cinul',
'ciobi',
'ciomp',
'cionc',
'ciont',
'ciori',
'ciort',
'cipca',
'cipcă',
'cipci',
'cipic',
'cipul',
'cirac',
'circa',
'circă',
'circe',
'circi',
'cireș',
'cirii',
'cirip',
'ciriș',
'cirta',
'cirtă',
'cirte',
'cirul',
'cirus',
'cisla',
'cislă',
'cisle',
'cista',
'cistă',
'ciste',
'citai',
'citam',
'citat',
'citau',
'cităm',
'citea',
'citeț',
'citez',
'citii',
'citim',
'citit',
'citro',
'citul',
'ciuci',
'ciuda',
'ciudă',
'ciude',
'ciudi',
'ciufi',
'ciuin',
'ciula',
'ciulă',
'ciule',
'ciuli',
'ciuma',
'ciumă',
'ciume',
'ciump',
'ciung',
'ciunt',
'ciupa',
'ciupă',
'ciupe',
'ciupi',
'ciuta',
'ciută',
'ciute',
'ciuți',
'civic',
'civil',
'civit',
'cizma',
'cizmă',
'cizme',
'claca',
'clacă',
'clada',
'cladă',
'clade',
'claia',
'claie',
'clama',
'clamă',
'clame',
'clamp',
'clanc',
'clanț',
'clapa',
'clapă',
'clapc',
'clape',
'clara',
'clară',
'clare',
'clari',
'clasa',
'clasă',
'clase',
'claun',
'clăci',
'clădi',
'clăia',
'clăii',
'clăim',
'clăit',
'clăti',
'clăzi',
'clean',
'clema',
'clemă',
'cleme',
'cleni',
'clica',
'clică',
'clici',
'clima',
'climă',
'clime',
'clina',
'clină',
'clinc',
'cline',
'cling',
'clini',
'clint',
'clipa',
'clipă',
'clipe',
'clipi',
'clips',
'clisa',
'clisă',
'clise',
'clișa',
'clișă',
'cliva',
'clivă',
'cloca',
'clocă',
'cloci',
'clomb',
'clona',
'clonă',
'clonc',
'clone',
'clonț',
'cloța',
'cloță',
'cloțe',
'clovn',
'clown',
'clupa',
'clupă',
'clupe',
'cluul',
'cneaz',
'cneji',
'cnezi',
'coacă',
'coace',
'coada',
'coadă',
'coade',
'coafa',
'coafă',
'coafe',
'coaja',
'coajă',
'coala',
'coală',
'coale',
'coama',
'coamă',
'coame',
'coana',
'coană',
'coane',
'coasa',
'coasă',
'coase',
'coate',
'cobai',
'cobăi',
'cobea',
'cobei',
'cobii',
'cobim',
'cobit',
'cobol',
'cobor',
'cobra',
'cobră',
'cobre',
'cobur',
'cobuz',
'cobza',
'cobză',
'cobze',
'cocăi',
'cocea',
'cocia',
'cocie',
'cocii',
'cociș',
'cocli',
'cocon',
'cocor',
'cocos',
'cocoș',
'cocoț',
'cocul',
'codai',
'codaj',
'codal',
'codam',
'codan',
'codaș',
'codat',
'codau',
'codăm',
'codea',
'codei',
'codex',
'codez',
'codia',
'codie',
'codii',
'codim',
'codit',
'codoș',
'codri',
'codru',
'codul',
'cofei',
'cofra',
'cofră',
'coiot',
'cojea',
'cojii',
'cojim',
'cojit',
'cojoc',
'colac',
'colai',
'colaj',
'colam',
'colan',
'colat',
'colau',
'colăm',
'colea',
'coleg',
'colei',
'colet',
'colez',
'colia',
'colic',
'colie',
'colii',
'colin',
'colir',
'colna',
'colnă',
'colne',
'colon',
'color',
'colos',
'colți',
'colul',
'colun',
'comat',
'combi',
'combo',
'comei',
'comet',
'comic',
'comis',
'comit',
'comod',
'comor',
'comun',
'comut',
'conac',
'conaș',
'conca',
'concă',
'conci',
'condu',
'coneț',
'conex',
'conga',
'conic',
'conta',
'contă',
'conte',
'conți',
'conul',
'copac',
'copai',
'copal',
'copan',
'copăi',
'copca',
'copcă',
'copce',
'copci',
'copia',
'copie',
'copii',
'copil',
'copoi',
'copra',
'copră',
'copre',
'copta',
'coptă',
'copte',
'copți',
'copul',
'corac',
'corai',
'coraj',
'coral',
'coran',
'corbi',
'corci',
'coree',
'corei',
'coreț',
'coreu',
'corfa',
'corfă',
'corfe',
'corii',
'corla',
'corlă',
'corle',
'corli',
'corni',
'coroi',
'corpi',
'corso',
'corul',
'corup',
'corzi',
'cosac',
'cosar',
'cosaș',
'cosea',
'cosei',
'cosii',
'cosim',
'cosit',
'cosoi',
'cosor',
'costa',
'costă',
'coste',
'coșar',
'coșea',
'coșii',
'coșim',
'coșit',
'coșul',
'cotai',
'cotam',
'cotar',
'cotat',
'cotau',
'cotăm',
'cotea',
'cotei',
'coteț',
'cotez',
'cotii',
'cotil',
'cotim',
'cotiș',
'cotit',
'cotoc',
'cotoi',
'coton',
'cotor',
'cotul',
'coțca',
'coțcă',
'coțce',
'coțci',
'coții',
'coviț',
'covor',
'covru',
'coxal',
'coxei',
'cozii',
'cozul',
'crabi',
'craca',
'cracă',
'craci',
'craii',
'crama',
'cramă',
'crame',
'crane',
'cranț',
'crapă',
'crape',
'crapi',
'crasa',
'crasă',
'crase',
'crași',
'craul',
'craun',
'craza',
'crază',
'craze',
'crăci',
'crăia',
'crăie',
'crăii',
'crăpa',
'crăpă',
'crăpe',
'crăpi',
'crâng',
'crâșc',
'creai',
'cream',
'creat',
'creau',
'creăm',
'crede',
'credo',
'creek',
'creez',
'crema',
'cremă',
'creme',
'creol',
'creps',
'cresc',
'creșa',
'creșă',
'creșe',
'creta',
'cretă',
'crete',
'crețe',
'creți',
'crezi',
'crezu',
'crida',
'cridă',
'cride',
'crier',
'crila',
'crilă',
'crile',
'crima',
'crimă',
'crime',
'crini',
'crița',
'criță',
'crițe',
'criza',
'criză',
'crize',
'croat',
'croia',
'croii',
'croim',
'croit',
'croma',
'cromă',
'cromo',
'cronc',
'crosa',
'crosă',
'crose',
'croșa',
'croșă',
'croșe',
'crown',
'cruce',
'cruci',
'cruda',
'crudă',
'crude',
'crunt',
'crupa',
'crupă',
'crupe',
'cruși',
'cruța',
'cruță',
'cruțe',
'cruți',
'cruzi',
'cuaga',
'cuarț',
'cubai',
'cubaj',
'cubam',
'cuban',
'cubat',
'cubau',
'cubăm',
'cubeb',
'cubez',
'cubic',
'cubii',
'cubul',
'cucăi',
'cucii',
'cucon',
'cucor',
'cucoș',
'cucui',
'cucul',
'cucuț',
'cufăr',
'cuget',
'cuhne',
'cuhni',
'cuiba',
'cuibă',
'cuier',
'cuina',
'cuină',
'cuine',
'cuini',
'cuiul',
'cuiuț',
'cuiva',
'cujba',
'cujbă',
'cujbe',
'culac',
'culca',
'culcă',
'culce',
'culci',
'culee',
'culeg',
'culei',
'cules',
'culic',
'culii',
'culma',
'culmă',
'culme',
'culmi',
'culot',
'culpa',
'culpă',
'culpe',
'culta',
'cultă',
'culte',
'culți',
'cuman',
'cumaș',
'cumâs',
'cumen',
'cumis',
'cumul',
'cumva',
'cunun',
'cupaj',
'cupar',
'cupea',
'cupei',
'cupeț',
'cupeu',
'cupid',
'cupit',
'cupiu',
'cupla',
'cuplă',
'cuple',
'cuplu',
'cupon',
'cupra',
'cupră',
'cupru',
'curai',
'curaj',
'curam',
'curat',
'curau',
'curăm',
'curăț',
'curba',
'curbă',
'curbe',
'curbi',
'curca',
'curcă',
'curci',
'curea',
'curei',
'curez',
'curgă',
'curge',
'curia',
'curie',
'curii',
'curiu',
'curma',
'curmă',
'curme',
'curmi',
'curry',
'cursa',
'cursă',
'curse',
'curta',
'curtă',
'curte',
'curți',
'curul',
'curuț',
'curva',
'curvă',
'curve',
'curvi',
'cusui',
'cusur',
'cusut',
'cușac',
'cușca',
'cușcă',
'cușer',
'cușma',
'cușmă',
'cușme',
'cuști',
'cutai',
'cutam',
'cutat',
'cutau',
'cutăm',
'cutea',
'cutei',
'cuter',
'cutez',
'cutia',
'cutie',
'cutii',
'cutra',
'cutră',
'cutre',
'cuțit',
'cuvei',
'cuvie',
'cuvin',
'cvarț',
'cvasi',
'dacei',
'dacic',
'dacii',
'dacit',
'dacul',
'dadei',
'dafia',
'dafie',
'dafii',
'dafin',
'daiac',
'daica',
'daică',
'daici',
'daira',
'dalac',
'dalaj',
'dalba',
'dalbă',
'dalbe',
'dalbi',
'dalei',
'dalia',
'dalie',
'dalta',
'daltă',
'damei',
'damii',
'damna',
'damnă',
'damul',
'dance',
'danci',
'dandi',
'dandy',
'danei',
'danez',
'danga',
'dania',
'danie',
'danii',
'dansa',
'dansă',
'danța',
'danță',
'danul',
'dapăr',
'darac',
'darda',
'dardă',
'darde',
'darea',
'daria',
'darie',
'darii',
'darmă',
'darme',
'darul',
'dasem',
'dasia',
'dasie',
'dasii',
'datai',
'datam',
'datat',
'datau',
'datăm',
'datei',
'datez',
'dativ',
'dator',
'datul',
'dații',
'dauna',
'daună',
'daune',
'dayac',
'dădea',
'dădui',
'dăgii',
'dăina',
'dăină',
'dălii',
'dălți',
'dănac',
'dănos',
'dănui',
'dăoli',
'dărab',
'dărac',
'dărăb',
'dărâm',
'dării',
'dărma',
'dărmă',
'dărme',
'dărmi',
'dărui',
'dății',
'dăula',
'dăulă',
'dăuli',
'dăuna',
'dăună',
'dâcii',
'dâlma',
'dâlmă',
'dâlme',
'dânsa',
'dârai',
'dâram',
'dârat',
'dârau',
'dârăm',
'dârâi',
'dârâm',
'dârât',
'dârei',
'dârez',
'dârje',
'dârji',
'dârza',
'dârză',
'dârze',
'dârzi',
'deasa',
'deasă',
'debil',
'debit',
'debut',
'debye',
'decad',
'decan',
'decar',
'decât',
'deces',
'decid',
'decis',
'decor',
'decul',
'dedai',
'dedal',
'dedam',
'dedat',
'dedau',
'dedăm',
'dedea',
'dedei',
'dedic',
'dedoi',
'deduc',
'dedus',
'defel',
'defer',
'degaj',
'deger',
'deget',
'deism',
'deist',
'dejei',
'dejoc',
'dejug',
'dejun',
'delas',
'delco',
'deleg',
'delei',
'delia',
'delie',
'delii',
'delir',
'deliu',
'deloc',
'delta',
'deltă',
'delte',
'deluț',
'demei',
'demis',
'demit',
'demiu',
'demna',
'demnă',
'demne',
'demni',
'demon',
'demos',
'denar',
'deneg',
'denia',
'denie',
'denii',
'densa',
'densă',
'dense',
'denși',
'depăn',
'depăr',
'depou',
'depui',
'depun',
'depus',
'derbi',
'derby',
'derea',
'dereg',
'deres',
'dereș',
'deriv',
'derma',
'dermă',
'derme',
'derog',
'desag',
'desec',
'desei',
'desen',
'deset',
'desfă',
'desiș',
'desul',
'deszi',
'deșca',
'deșcă',
'deșce',
'deșel',
'deșeu',
'deșii',
'deșir',
'dește',
'dești',
'detei',
'detun',
'deție',
'deții',
'dețin',
'dețiu',
'dever',
'devia',
'devie',
'devii',
'devin',
'deviu',
'deviz',
'devla',
'devlă',
'devle',
'devon',
'devot',
'dezic',
'dezis',
'diace',
'diata',
'diată',
'diate',
'dibla',
'diblă',
'dible',
'diblu',
'dibol',
'dibui',
'dicta',
'dictă',
'dictu',
'dieci',
'diena',
'dienă',
'diene',
'dieta',
'dietă',
'diete',
'dieza',
'dieză',
'diezi',
'difer',
'difuz',
'diger',
'digit',
'digul',
'dihai',
'dihoc',
'dihor',
'dijma',
'dijmă',
'dijme',
'dilat',
'dilea',
'dilia',
'dilie',
'dilii',
'dilim',
'dilit',
'diliu',
'dilua',
'diluă',
'dimer',
'dimia',
'dimie',
'dimii',
'dimon',
'dinam',
'dinar',
'dinee',
'dinei',
'dineu',
'dingo',
'dinte',
'dința',
'dință',
'dinți',
'dioda',
'diodă',
'diode',
'dioic',
'dipod',
'dipol',
'direg',
'dires',
'dirig',
'disco',
'disec',
'ditai',
'diurn',
'divan',
'divei',
'divid',
'divin',
'dixie',
'doaga',
'doagă',
'doage',
'doapa',
'doapă',
'doape',
'doară',
'doare',
'dobaș',
'dobei',
'dobor',
'doboș',
'dobru',
'docar',
'docil',
'docta',
'doctă',
'docte',
'docți',
'docul',
'dodea',
'dodei',
'dodii',
'dodim',
'dodit',
'dogar',
'dogea',
'dogii',
'dogim',
'dogit',
'dogma',
'dogmă',
'dogme',
'dogul',
'dohot',
'doica',
'doică',
'doici',
'doime',
'doimi',
'doina',
'doină',
'doine',
'doini',
'doiul',
'dolar',
'dolby',
'dolce',
'dolia',
'dolie',
'dolii',
'doliu',
'dolly',
'dolul',
'domei',
'domin',
'domni',
'domnu',
'domol',
'domra',
'domră',
'domre',
'domul',
'donai',
'donam',
'donat',
'donau',
'donăm',
'donez',
'donor',
'dopai',
'dopaj',
'dopam',
'dopat',
'dopau',
'dopăm',
'dopez',
'dopul',
'dorea',
'doric',
'dorii',
'dorim',
'dorit',
'dormi',
'dorna',
'dornă',
'dorne',
'dorul',
'doruț',
'dosar',
'dosea',
'dosii',
'dosim',
'dosit',
'dospi',
'dosul',
'dotai',
'dotal',
'dotam',
'dotat',
'dotau',
'dotăm',
'dotei',
'dotez',
'doxei',
'dozai',
'dozaj',
'dozam',
'dozat',
'dozau',
'dozăm',
'dozei',
'dozez',
'draci',
'dracu',
'draga',
'dragă',
'drage',
'dragi',
'drain',
'draiv',
'drama',
'dramă',
'drame',
'drapa',
'drapă',
'drâng',
'drege',
'dregi',
'drela',
'drelă',
'drele',
'drena',
'drenă',
'drene',
'drept',
'dresa',
'dresă',
'drese',
'dreși',
'drețe',
'dreve',
'drink',
'dripi',
'drive',
'drobi',
'droga',
'drogă',
'drops',
'drota',
'drotă',
'druga',
'drugă',
'drugi',
'druid',
'drupa',
'drupă',
'drupe',
'drusa',
'drusă',
'druse',
'druza',
'druză',
'druze',
'duale',
'duant',
'dubas',
'dubea',
'dubei',
'dubii',
'dubim',
'dubit',
'dubiu',
'dubla',
'dublă',
'duble',
'dubli',
'dublu',
'ducal',
'ducat',
'ducăi',
'ducea',
'ducem',
'ducii',
'dudău',
'dudei',
'dudui',
'dudul',
'duela',
'duelă',
'duete',
'duhan',
'duhni',
'duhul',
'duios',
'duium',
'dulap',
'dulăi',
'dulău',
'dulce',
'dulci',
'dulia',
'dulie',
'dulii',
'dumei',
'dumic',
'dunei',
'dunga',
'dungă',
'dungi',
'dunit',
'duoul',
'dupac',
'dupăi',
'dupca',
'dupcă',
'dupce',
'dupii',
'dupre',
'dupul',
'durai',
'duram',
'duran',
'durat',
'durau',
'durăi',
'durăm',
'durăt',
'durda',
'durdă',
'durde',
'durea',
'durei',
'durez',
'durig',
'durii',
'durit',
'durmi',
'duros',
'durui',
'durul',
'durut',
'durzi',
'dusei',
'dusul',
'dușca',
'dușcă',
'dușii',
'duște',
'duști',
'dușul',
'dutca',
'dutcă',
'dutce',
'duzei',
'duzii',
'dvori',
'dzelo',
'eboșa',
'eboșă',
'eboșe',
'ecart',
'echea',
'echei',
'echer',
'ecler',
'ecoul',
'ecran',
'ecvin',
'edeme',
'edict',
'edili',
'edita',
'edită',
'educa',
'educă',
'educe',
'educi',
'efebi',
'efect',
'efeți',
'efila',
'efilă',
'eflux',
'efori',
'efort',
'egala',
'egală',
'egale',
'egali',
'egida',
'egidă',
'egide',
'elani',
'eleat',
'elena',
'elenă',
'elene',
'eleni',
'eleva',
'elevă',
'eleve',
'elevi',
'elfii',
'elful',
'elice',
'elida',
'elidă',
'elina',
'elină',
'eline',
'elini',
'elita',
'elită',
'elite',
'eluda',
'eludă',
'email',
'emana',
'emană',
'emane',
'emani',
'embol',
'emden',
'emerg',
'emeri',
'emers',
'emiri',
'emisa',
'emisă',
'emise',
'emiși',
'emită',
'emite',
'emiți',
'emuli',
'emuul',
'enorm',
'enoți',
'enunț',
'eocen',
'eolit',
'eonii',
'eonul',
'epata',
'epată',
'epava',
'epavă',
'epave',
'epica',
'epică',
'epice',
'epici',
'epila',
'epilă',
'epiul',
'epoca',
'epocă',
'epoci',
'epoda',
'epodă',
'epode',
'epoha',
'epohă',
'epohe',
'eponj',
'epura',
'epură',
'epure',
'equus',
'erata',
'erată',
'erate',
'erați',
'erbiu',
'erect',
'erede',
'erele',
'erete',
'ereți',
'erezi',
'ergan',
'ergii',
'ergul',
'erhei',
'erija',
'erijă',
'ermit',
'eroda',
'erodă',
'eroic',
'eroii',
'erori',
'eroul',
'erupă',
'erupe',
'erupi',
'erupt',
'erzaț',
'eseul',
'espui',
'espun',
'espus',
'estaz',
'ester',
'estet',
'estic',
'estiv',
'eston',
'estre',
'estru',
'estul',
'eșuai',
'eșuam',
'eșuat',
'eșuau',
'eșuăm',
'eșuez',
'etaja',
'etajă',
'etaje',
'etala',
'etală',
'etanș',
'etapa',
'etapă',
'etape',
'etate',
'etăți',
'etena',
'etenă',
'etene',
'eteri',
'etern',
'ethos',
'etiaj',
'etica',
'etică',
'etice',
'etici',
'etila',
'etilă',
'etili',
'etira',
'etiră',
'etnia',
'etnic',
'etnie',
'etnii',
'etola',
'etolă',
'etole',
'etufa',
'etufă',
'etuva',
'etuvă',
'etuve',
'eului',
'eunuc',
'eurip',
'evada',
'evadă',
'evaza',
'evază',
'eving',
'evins',
'evita',
'evită',
'evite',
'eviți',
'evoca',
'evocă',
'evoce',
'evoci',
'evrei',
'evreu',
'evuri',
'exact',
'exala',
'exală',
'exale',
'exali',
'exalt',
'exarh',
'exces',
'excit',
'exert',
'exhib',
'exigă',
'exige',
'exigi',
'exila',
'exilă',
'exina',
'exină',
'exine',
'exist',
'expia',
'expie',
'expir',
'expre',
'expui',
'expun',
'expus',
'extaz',
'extra',
'exult',
'ezita',
'ezită',
'ezite',
'eziți',
'facem',
'facil',
'facla',
'faclă',
'facle',
'facto',
'fadei',
'fadul',
'fagii',
'fagot',
'fagul',
'fagur',
'faima',
'faimă',
'faime',
'faina',
'faină',
'faine',
'faini',
'faiul',
'falca',
'falcă',
'falce',
'falei',
'falet',
'falia',
'falie',
'falii',
'falit',
'falsa',
'falsă',
'false',
'falșa',
'falșă',
'falșe',
'falși',
'falun',
'falus',
'famat',
'famen',
'fanai',
'fanal',
'fanam',
'fanar',
'fanat',
'fanau',
'fanăm',
'fanda',
'fandă',
'fanez',
'fanii',
'fanon',
'fanta',
'fantă',
'fante',
'fanți',
'fanul',
'fapta',
'faptă',
'fapte',
'fapți',
'farad',
'farba',
'farbă',
'farbe',
'farda',
'fardă',
'farin',
'farmă',
'farme',
'farsa',
'farsă',
'farse',
'farul',
'fason',
'fasta',
'fastă',
'faste',
'faști',
'fatal',
'fatum',
'fault',
'fauna',
'faună',
'faune',
'fauni',
'fauri',
'favor',
'favus',
'faxul',
'fazan',
'fazei',
'fazii',
'făcău',
'făcea',
'făcui',
'făcut',
'făgaș',
'făget',
'făina',
'făină',
'făini',
'fălci',
'fălea',
'fălii',
'fălim',
'fălit',
'fălos',
'făraș',
'fărâm',
'fărbi',
'fărma',
'fărmă',
'fărme',
'fărmi',
'făsui',
'fășia',
'fășie',
'fășii',
'fătat',
'fătau',
'fătoi',
'fătul',
'fățiș',
'fățos',
'fățui',
'făuri',
'fânar',
'fânaț',
'fânul',
'fârta',
'fârtă',
'fârte',
'fâsăi',
'fâsâi',
'fâsei',
'fâsul',
'fâșăi',
'fâșâi',
'fâșia',
'fâșie',
'fâșii',
'fâșul',
'fâțâi',
'fâței',
'febra',
'febră',
'febre',
'fecal',
'feder',
'feele',
'felah',
'felea',
'felei',
'felia',
'felie',
'felii',
'felin',
'felon',
'felul',
'femei',
'femel',
'femur',
'fenec',
'fenic',
'fenil',
'fenix',
'fenol',
'fenta',
'fentă',
'fente',
'ferat',
'ferdu',
'ferea',
'ferec',
'feric',
'ferii',
'ferim',
'ferit',
'ferma',
'fermă',
'ferme',
'fermi',
'feros',
'ferța',
'ferță',
'ferțe',
'ferul',
'fesei',
'festa',
'festă',
'feste',
'fesul',
'feșei',
'feșii',
'fetal',
'fetea',
'fetei',
'fetia',
'fetid',
'fetie',
'fetii',
'fetim',
'fetiș',
'fetit',
'fetru',
'fetus',
'feței',
'feții',
'feuda',
'feudă',
'feude',
'fiala',
'fială',
'fiale',
'fiara',
'fiară',
'fiare',
'fibra',
'fibră',
'fibre',
'ficat',
'ficși',
'ficus',
'fidea',
'fidel',
'fider',
'fiece',
'fierb',
'fiere',
'fieri',
'fiert',
'fiesc',
'fiful',
'fiica',
'fiică',
'fiice',
'fiind',
'filai',
'filam',
'filat',
'filau',
'filăm',
'filee',
'filei',
'filer',
'filet',
'fileu',
'filez',
'filit',
'filma',
'filmă',
'filme',
'filon',
'final',
'finea',
'finei',
'finet',
'finii',
'finim',
'finiș',
'finit',
'finul',
'fiola',
'fiolă',
'fiole',
'fionc',
'fiong',
'fiord',
'fiori',
'firav',
'firea',
'firet',
'firez',
'firii',
'firiz',
'firma',
'firmă',
'firme',
'firos',
'firul',
'firuț',
'fisei',
'fișai',
'fișam',
'fișat',
'fișau',
'fișăm',
'fișca',
'fișcă',
'fișei',
'fișet',
'fișez',
'fișic',
'fișiu',
'fiști',
'fișul',
'fitil',
'fitui',
'fiței',
'fiuți',
'fixai',
'fixaj',
'fixam',
'fixat',
'fixau',
'fixăm',
'fixei',
'fixez',
'fixul',
'fizic',
'flama',
'flamă',
'flame',
'flana',
'flană',
'flanc',
'flane',
'flasc',
'flash',
'flata',
'flată',
'flaut',
'fleac',
'fleoș',
'fleșa',
'fleșă',
'fleșe',
'flețe',
'fleți',
'flint',
'flirt',
'floci',
'flora',
'floră',
'flore',
'flori',
'flota',
'flotă',
'flote',
'fluaj',
'fluda',
'fludă',
'flude',
'fluid',
'fluor',
'fluul',
'foaci',
'foaia',
'foaie',
'foale',
'foame',
'fobia',
'fobie',
'fobii',
'focal',
'focar',
'focii',
'focos',
'focși',
'focul',
'fodra',
'fodră',
'fodre',
'foehn',
'foene',
'föhne',
'foiai',
'foiam',
'foiau',
'foile',
'foind',
'foios',
'foiră',
'foire',
'foiri',
'foise',
'foiși',
'foita',
'foită',
'foite',
'foița',
'foiță',
'foițe',
'foiți',
'foiul',
'folia',
'folie',
'folii',
'folio',
'foliu',
'folos',
'fonda',
'fondă',
'fondu',
'fonem',
'fonfi',
'fonia',
'fonic',
'fonie',
'fonii',
'fonon',
'fonta',
'fontă',
'fonte',
'fonul',
'forai',
'foraj',
'foram',
'foraș',
'forat',
'forau',
'forăi',
'forăm',
'forez',
'forja',
'forjă',
'forje',
'forma',
'formă',
'forme',
'forte',
'forța',
'forță',
'forțe',
'forul',
'forum',
'fosei',
'fosil',
'fosta',
'fostă',
'foste',
'foșăi',
'foșni',
'foști',
'fotei',
'fotel',
'foton',
'fotul',
'foții',
'foxul',
'fraga',
'fragă',
'fragi',
'fraht',
'franc',
'franj',
'franș',
'frapa',
'frapă',
'frate',
'frați',
'fraza',
'frază',
'fraze',
'frâie',
'frâna',
'frână',
'frânc',
'frâne',
'frâng',
'frânt',
'frâul',
'freca',
'frecă',
'frece',
'freci',
'freon',
'freta',
'fretă',
'frete',
'freza',
'freză',
'freze',
'frica',
'frică',
'frici',
'frigă',
'frige',
'frigi',
'fript',
'friza',
'friză',
'frize',
'front',
'frota',
'frotă',
'fruct',
'frupt',
'frust',
'ftori',
'fucus',
'fudul',
'fufei',
'fugar',
'fugea',
'fugii',
'fugim',
'fugit',
'fugos',
'fugui',
'fuior',
'fular',
'fulat',
'fulau',
'fulee',
'fuleu',
'fulgi',
'fulia',
'fulie',
'fulii',
'fulul',
'fumai',
'fumam',
'fumar',
'fumat',
'fumau',
'fumăm',
'fumez',
'fumui',
'fumul',
'funda',
'fundă',
'funde',
'funga',
'fungă',
'fungi',
'funia',
'funie',
'funii',
'funți',
'funzi',
'fuoco',
'furai',
'furaj',
'furam',
'furan',
'furat',
'furau',
'furăm',
'furca',
'furcă',
'furci',
'furda',
'furia',
'furie',
'furii',
'furiș',
'furou',
'furul',
'fusar',
'fusei',
'fusta',
'fustă',
'fuste',
'fusul',
'fuște',
'fuști',
'futil',
'fuxul',
'fuzee',
'fuzel',
'fuzen',
'fuzit',
'gabia',
'gabie',
'gabii',
'gabja',
'gabjă',
'gabje',
'gabro',
'gacii',
'gaeli',
'gafai',
'gafam',
'gafat',
'gafau',
'gafăm',
'gafei',
'gafez',
'gagat',
'gagic',
'gagii',
'gagiu',
'gagul',
'gaiac',
'gaial',
'gaibe',
'gaica',
'gaică',
'gaida',
'gaidă',
'gaide',
'gaița',
'gaiță',
'gaițe',
'gaiza',
'gaiză',
'gaize',
'gajul',
'galba',
'galbă',
'galbe',
'galei',
'galeș',
'galet',
'galez',
'galic',
'galii',
'galiș',
'galiu',
'galon',
'galop',
'galoș',
'galul',
'gamba',
'gambă',
'gambe',
'gamei',
'gamet',
'ganaș',
'ganga',
'gangă',
'gange',
'garai',
'garaj',
'garam',
'garat',
'garau',
'garăm',
'garda',
'gardă',
'garez',
'garou',
'gasul',
'gașca',
'gașcă',
'gașei',
'gater',
'gatul',
'gaura',
'gaură',
'gauri',
'gauss',
'gauși',
'gavaj',
'gazai',
'gazam',
'gazat',
'gazau',
'gazăm',
'gazda',
'gazdă',
'gazde',
'gazel',
'gazeu',
'gazez',
'gazon',
'gazos',
'gazul',
'găbji',
'găbui',
'găici',
'găile',
'găina',
'găină',
'găini',
'găman',
'găoci',
'gării',
'gărzi',
'găsea',
'găsii',
'găsim',
'găsit',
'găști',
'gătai',
'gătam',
'gătat',
'gătau',
'gătăm',
'gătea',
'gătej',
'gătez',
'gătii',
'gătim',
'gătit',
'găuna',
'găună',
'găuni',
'găuri',
'găvan',
'găzar',
'gâcea',
'gâcii',
'gâcim',
'gâcit',
'gâdea',
'gâdei',
'gâdil',
'gâfâi',
'gâgâi',
'gâjâi',
'gâlca',
'gâlcă',
'gâlci',
'gâlma',
'gâlmă',
'gâlme',
'gândi',
'gânji',
'gârla',
'gârlă',
'gârle',
'gâsca',
'gâscă',
'gâște',
'gâtar',
'gâtos',
'gâtui',
'gâtul',
'gâței',
'gâzei',
'gâzii',
'geaba',
'geaca',
'geacă',
'geala',
'geală',
'geale',
'geamă',
'geana',
'geană',
'gebea',
'gecii',
'gecko',
'gelep',
'geliv',
'gelos',
'gelui',
'gelul',
'gemăt',
'gemea',
'gemei',
'gemem',
'gemen',
'gemet',
'gemin',
'gemui',
'gemul',
'gemut',
'genat',
'genei',
'genic',
'genii',
'geniu',
'genol',
'genom',
'genți',
'genul',
'geoda',
'geodă',
'geode',
'geoid',
'gepid',
'gerah',
'gerai',
'geram',
'gerar',
'gerat',
'gerau',
'gerăm',
'geret',
'gerez',
'gerid',
'geros',
'gerui',
'gerul',
'getei',
'getic',
'getul',
'geții',
'gheba',
'ghebă',
'ghebe',
'gheme',
'ghete',
'gheto',
'gheți',
'ghici',
'ghida',
'ghidă',
'ghide',
'ghidi',
'ghili',
'ghint',
'ghinț',
'ghiob',
'ghioc',
'ghiol',
'ghioș',
'ghips',
'ghiuj',
'ghiul',
'ghizd',
'ghizi',
'giaca',
'giacă',
'giace',
'gibon',
'gigăi',
'gigea',
'gigul',
'gimia',
'gimie',
'gimii',
'ginea',
'ginii',
'ginim',
'ginit',
'ginta',
'gintă',
'ginte',
'ginți',
'ginul',
'girai',
'giram',
'girat',
'girau',
'girăm',
'girez',
'girul',
'girus',
'gitan',
'glaja',
'glajă',
'gland',
'glanț',
'glasa',
'glasă',
'glăji',
'glenc',
'gliei',
'gliom',
'glisa',
'glisă',
'globi',
'glodi',
'glonț',
'glosa',
'glosă',
'glose',
'glota',
'glotă',
'glote',
'gluga',
'glugă',
'glugi',
'gluma',
'glumă',
'glume',
'glumi',
'gnais',
'gnomi',
'gnuul',
'goala',
'goală',
'goale',
'goana',
'goană',
'goane',
'gobăi',
'godac',
'goden',
'godeu',
'godia',
'godie',
'godii',
'godin',
'gofra',
'gofră',
'gogea',
'gogii',
'gogim',
'gogit',
'gogon',
'gogul',
'golan',
'golaș',
'golea',
'golii',
'golim',
'golit',
'golul',
'goluț',
'gomaj',
'gomei',
'gomos',
'gonaș',
'gonea',
'gonii',
'gonim',
'gonio',
'gonit',
'gopac',
'gorun',
'gotca',
'gotcă',
'gotce',
'gotic',
'gotul',
'goții',
'gozul',
'graba',
'grabă',
'grabe',
'grada',
'gradă',
'grade',
'grafe',
'grafi',
'grajd',
'grame',
'grapa',
'grapă',
'grape',
'grasa',
'grasă',
'grase',
'grași',
'grata',
'graur',
'grava',
'gravă',
'grave',
'gravi',
'grăbi',
'grăia',
'grăii',
'grăim',
'grăit',
'grăpa',
'grăpă',
'grâie',
'grâne',
'grâul',
'greai',
'gream',
'great',
'greau',
'greăm',
'grece',
'greci',
'greez',
'grefa',
'grefă',
'grefe',
'grefi',
'greii',
'grele',
'grena',
'greoi',
'gresa',
'gresă',
'greși',
'grețe',
'greți',
'greul',
'greva',
'grevă',
'greve',
'grier',
'grifa',
'grifă',
'grife',
'grija',
'grijă',
'griji',
'grila',
'grilă',
'grile',
'grill',
'grima',
'grimă',
'grime',
'grind',
'gripa',
'gripă',
'gripe',
'griul',
'griva',
'grivă',
'grive',
'grivi',
'griza',
'griză',
'grizu',
'groba',
'grobă',
'grobe',
'grobi',
'grofi',
'gromi',
'groom',
'gropi',
'groși',
'grota',
'grotă',
'grote',
'gruia',
'gruie',
'grund',
'grunz',
'grupa',
'grupă',
'grupe',
'guano',
'guard',
'guașa',
'guașă',
'guașe',
'gubav',
'gubei',
'gudur',
'guelf',
'guița',
'guiță',
'guițe',
'gujon',
'gulag',
'gulaș',
'guler',
'gulia',
'gulie',
'gulii',
'gumai',
'gumaj',
'gumam',
'gumat',
'gumau',
'gumăm',
'gumei',
'gumez',
'gunoi',
'gupei',
'gurăi',
'gureș',
'gurii',
'gurma',
'gurmă',
'gurme',
'gurna',
'gurnă',
'gurne',
'gurui',
'gurul',
'gurut',
'guseu',
'gusta',
'gustă',
'guste',
'gușat',
'gușii',
'guști',
'gutei',
'gutos',
'gutui',
'guvid',
'guzii',
'guzla',
'guzlă',
'guzle',
'guzul',
'haban',
'habar',
'hacul',
'Hades',
'hagii',
'hagiu',
'haham',
'haida',
'haide',
'haiku',
'haina',
'haină',
'haine',
'haini',
'haios',
'haita',
'haită',
'haite',
'haiti',
'haiul',
'halaj',
'halal',
'halat',
'halău',
'halba',
'halbă',
'halbe',
'halca',
'halcă',
'halda',
'haldă',
'halde',
'halea',
'halei',
'halfi',
'halii',
'halim',
'halit',
'halon',
'halor',
'halou',
'halta',
'haltă',
'halte',
'halul',
'halva',
'hamac',
'hamal',
'hamei',
'hamiș',
'hamit',
'hamul',
'hamut',
'hanap',
'hanat',
'hanii',
'hansa',
'hansă',
'hanse',
'hanța',
'hanță',
'hanțe',
'hanul',
'hapax',
'hapca',
'hapcă',
'hapci',
'haple',
'hapul',
'harac',
'harag',
'haram',
'harap',
'harem',
'hares',
'harfa',
'harfă',
'harfe',
'harpa',
'harpă',
'harpe',
'harșa',
'harta',
'hartă',
'harța',
'harță',
'harțe',
'harți',
'harul',
'hasca',
'hască',
'hașca',
'hașcă',
'hașeu',
'hașiș',
'hașma',
'hașmă',
'hașme',
'hatâr',
'hatia',
'hatie',
'hatii',
'hatul',
'havai',
'havaj',
'havam',
'havan',
'havat',
'havau',
'havăm',
'havez',
'havra',
'havră',
'havre',
'havuz',
'hazna',
'haznă',
'hazne',
'hazul',
'hăbuc',
'hăcui',
'hăiai',
'hăiam',
'hăiau',
'hăind',
'hăini',
'hăiră',
'hăire',
'hăiri',
'hăisa',
'hăise',
'hăiși',
'hăiți',
'hălci',
'hămăi',
'hămei',
'hămui',
'hăpăi',
'hărăi',
'hărău',
'hărți',
'hăști',
'hătea',
'hătii',
'hătim',
'hătit',
'hățaș',
'hățea',
'hății',
'hățim',
'hățiș',
'hățit',
'hățui',
'hățul',
'hăuia',
'hăuie',
'hăuii',
'hăuim',
'hăuit',
'hăuli',
'hăuri',
'hâcâi',
'hâdei',
'hâdul',
'hâiai',
'hâiam',
'hâiau',
'hâind',
'hâiră',
'hâire',
'hâiri',
'hâise',
'hâiși',
'hâita',
'hâită',
'hâite',
'hâiți',
'hâmâi',
'hârăi',
'hârău',
'hârâi',
'hârca',
'hârcă',
'hârci',
'hârei',
'hârsi',
'hârța',
'hârță',
'hârțe',
'hârți',
'hâșăi',
'hâșâi',
'hâtra',
'hâtră',
'hâtre',
'hâtri',
'hâtru',
'hâțâi',
'hâțân',
'hâzea',
'hâzii',
'hâzim',
'hâzit',
'heavy',
'hecht',
'heder',
'hehei',
'heiul',
'helge',
'helgi',
'helii',
'helio',
'heliu',
'helul',
'hemul',
'hends',
'henri',
'henry',
'hepar',
'herma',
'hermă',
'herme',
'hersa',
'hersă',
'herse',
'hertz',
'herul',
'hevea',
'hexan',
'hiada',
'hiadă',
'hiade',
'hibei',
'hicăi',
'hidos',
'hidra',
'hidră',
'hidre',
'hidro',
'hiena',
'hienă',
'hiene',
'hierb',
'hifei',
'hilar',
'hilea',
'hiler',
'hilot',
'hilul',
'himen',
'hindi',
'hioid',
'hiolă',
'hiper',
'hipic',
'hippy',
'hirta',
'hirtă',
'hirte',
'hitit',
'hitul',
'hleab',
'hlizi',
'hoața',
'hoață',
'hoațe',
'hoban',
'hobăi',
'hobâc',
'hobby',
'hobot',
'hodei',
'hogaș',
'hogea',
'hogei',
'hogii',
'hoher',
'hohot',
'hojma',
'hojmă',
'holba',
'holbă',
'holca',
'holcă',
'holci',
'holda',
'holdă',
'holde',
'holei',
'holia',
'holie',
'holii',
'holul',
'homar',
'honui',
'honul',
'hopai',
'hopăi',
'hopul',
'horai',
'horam',
'horau',
'horăi',
'horâi',
'horâm',
'horât',
'horda',
'hordă',
'horde',
'horea',
'horei',
'horeț',
'horii',
'horim',
'horit',
'horst',
'horul',
'hotar',
'hotei',
'hotel',
'hotul',
'hoțea',
'hoția',
'hoție',
'hoții',
'hoțim',
'hoțiș',
'hoțit',
'hoțul',
'house',
'hrana',
'hrană',
'hrane',
'hrăni',
'hrăpi',
'hrean',
'hribi',
'hruba',
'hrubă',
'hrube',
'hucii',
'hucul',
'hudei',
'huhur',
'huiau',
'huiet',
'huila',
'huilă',
'huile',
'huind',
'huiră',
'huire',
'huiri',
'huise',
'huița',
'huiță',
'huițe',
'huiți',
'huium',
'hulea',
'hulei',
'hulii',
'hulim',
'hulit',
'hulpe',
'hulpi',
'hulub',
'hului',
'humei',
'humic',
'humor',
'humos',
'humui',
'humus',
'hunii',
'hunul',
'hural',
'hurca',
'hurcă',
'hurci',
'hurez',
'huria',
'hurie',
'hurii',
'huron',
'hurta',
'hurtă',
'hurte',
'hurui',
'husar',
'husăș',
'husca',
'huscă',
'husei',
'husit',
'husoș',
'huște',
'huști',
'huțan',
'huțul',
'huțuț',
'huzur',
'iacei',
'iacii',
'iacul',
'iadeș',
'iadul',
'iambi',
'iarba',
'iarbă',
'iarna',
'iarnă',
'iartă',
'iarzi',
'iasca',
'iască',
'iasta',
'iastă',
'iaste',
'iatac',
'iaurt',
'iavaș',
'iazig',
'iazma',
'iazmă',
'iazul',
'ibiși',
'ibric',
'icnea',
'icnet',
'icnii',
'icnim',
'icnit',
'icose',
'icrei',
'icter',
'ictus',
'icuri',
'ideai',
'ideal',
'ideam',
'ideat',
'ideau',
'ideăm',
'ideea',
'ideez',
'ideii',
'idele',
'idila',
'idilă',
'idile',
'idiom',
'idiot',
'idoli',
'idoul',
'idrei',
'iedei',
'iedul',
'ieduț',
'iepei',
'ierbi',
'ierec',
'ierei',
'iereu',
'ierha',
'ierhă',
'ierhe',
'ierna',
'iernă',
'ierni',
'ierta',
'iertă',
'ierte',
'ierți',
'ierul',
'iesle',
'iesta',
'ieste',
'ieșea',
'ieșii',
'ieșim',
'ieșit',
'iești',
'ietac',
'iezea',
'iezer',
'iezii',
'iezim',
'iezit',
'iezme',
'ifose',
'ignam',
'ignar',
'ignat',
'ignee',
'ignei',
'igneu',
'ignor',
'iilor',
'ijele',
'ilaie',
'ilara',
'ilară',
'ilare',
'ilari',
'ilăul',
'ileie',
'ilene',
'ileon',
'ileul',
'ileus',
'iliac',
'ilice',
'ilion',
'ilira',
'iliră',
'ilire',
'iliri',
'iloți',
'imago',
'imala',
'imală',
'imale',
'imami',
'imani',
'imens',
'imerg',
'imers',
'imita',
'imită',
'imite',
'imiți',
'imnic',
'imnul',
'imola',
'imolă',
'imoși',
'impar',
'impas',
'impia',
'impie',
'impii',
'impiu',
'impui',
'impun',
'impur',
'impus',
'imput',
'imuna',
'imună',
'imund',
'imune',
'imuni',
'imuri',
'inapt',
'inari',
'incas',
'incaș',
'inchi',
'incit',
'incot',
'incub',
'incul',
'indan',
'index',
'indic',
'indiu',
'indol',
'induc',
'indus',
'inele',
'inema',
'inemă',
'ineme',
'inemi',
'inept',
'inerm',
'inert',
'inețe',
'infam',
'infim',
'infix',
'infra',
'infuz',
'inhib',
'inica',
'inică',
'inice',
'inici',
'iniei',
'inima',
'inimă',
'inime',
'inimi',
'inion',
'inița',
'iniță',
'inițe',
'inova',
'inovă',
'input',
'insei',
'insul',
'inșii',
'inter',
'intim',
'intra',
'intră',
'intre',
'intri',
'intru',
'intui',
'inund',
'inuri',
'invar',
'invit',
'invoc',
'iobag',
'iodat',
'iodic',
'iodul',
'iofca',
'iolei',
'ionic',
'ionii',
'ionit',
'ioniu',
'ionul',
'iosag',
'iotei',
'iotul',
'iovei',
'iovii',
'iovul',
'ipeca',
'ipsos',
'irato',
'irbis',
'ireal',
'iriga',
'irigă',
'irige',
'irigi',
'iriși',
'irita',
'irită',
'irite',
'iriți',
'iriza',
'iriză',
'irmos',
'irosi',
'irozi',
'iruga',
'irugă',
'irugi',
'irump',
'irumt',
'irupă',
'irupe',
'irupi',
'irupt',
'iruri',
'iscai',
'iscam',
'iscat',
'iscau',
'iscăm',
'islam',
'islaz',
'isnaf',
'isopi',
'ispas',
'ispol',
'istea',
'istei',
'isteț',
'istor',
'istov',
'istui',
'ișala',
'ișlic',
'iștia',
'iteme',
'itemi',
'ițani',
'ițare',
'ițari',
'ițeai',
'ițeam',
'ițeau',
'ițele',
'ițesc',
'ițiiu',
'ițind',
'ițiră',
'ițire',
'ițiri',
'ițise',
'ițiși',
'ițita',
'ițită',
'ițite',
'ițiți',
'iubea',
'iubeț',
'iubii',
'iubim',
'iubit',
'iudei',
'iudeu',
'iugăr',
'iuiai',
'iuiam',
'iuiau',
'iuind',
'iuiră',
'iuire',
'iuiri',
'iuise',
'iuiși',
'iuiți',
'iulie',
'iunie',
'iurăș',
'iureș',
'iurta',
'iurtă',
'iurte',
'iuruș',
'iusul',
'iușca',
'iușcă',
'iușni',
'iuști',
'iutea',
'iutei',
'iuțar',
'iuțea',
'iuții',
'iuțim',
'iuțit',
'iuxta',
'iuxtă',
'iuxte',
'ivăre',
'iveai',
'iveam',
'iveau',
'iveli',
'ivere',
'ivesc',
'ivind',
'iviră',
'ivire',
'iviri',
'ivise',
'iviși',
'ivita',
'ivită',
'ivite',
'iviți',
'ivrit',
'izbea',
'izbei',
'izbii',
'izbim',
'izbit',
'izbuc',
'izdat',
'izina',
'izină',
'izine',
'izini',
'izlaz',
'izmei',
'izola',
'izolă',
'izuri',
'izvod',
'izvor',
'îmbăt',
'îmbia',
'îmbib',
'îmbie',
'îmbii',
'îmbin',
'îmbla',
'îmblă',
'îmble',
'îmbli',
'îmblu',
'îmbuc',
'îmbun',
'împac',
'împle',
'împli',
'împlu',
'împui',
'împut',
'înalt',
'înalț',
'încai',
'încap',
'încât',
'încep',
'încet',
'încoa',
'încot',
'încui',
'încur',
'îndes',
'îndoi',
'îndop',
'îndur',
'îneca',
'înecă',
'înece',
'îneci',
'înfăș',
'înfăț',
'înfia',
'înfie',
'înfig',
'înfir',
'înfoc',
'înfoi',
'îngân',
'înger',
'înham',
'înhap',
'înhaț',
'înjug',
'înjur',
'înmia',
'înmii',
'înmoi',
'înnod',
'înnoi',
'înota',
'înotă',
'înoți',
'înrăi',
'însam',
'însei',
'însem',
'însor',
'însul',
'înșel',
'înșii',
'înșir',
'întâi',
'întin',
'întra',
'întră',
'între',
'întri',
'întru',
'înțep',
'înțes',
'învăț',
'învia',
'învie',
'învii',
'învoi',
'îraca',
'jabia',
'jabie',
'jabii',
'jabou',
'jabra',
'jabră',
'jabre',
'jabul',
'jacaș',
'jacăi',
'jacul',
'jadul',
'jaful',
'jaiul',
'jalba',
'jalbă',
'jalbe',
'jalea',
'jalei',
'jaleo',
'jaleș',
'jalet',
'jalon',
'jamba',
'jambă',
'jambe',
'jambu',
'jamei',
'jamna',
'jamnă',
'jamne',
'janta',
'jantă',
'jante',
'japca',
'japcă',
'japei',
'japii',
'japiu',
'japon',
'japșa',
'japșă',
'japșe',
'japul',
'jarca',
'jarcă',
'jarda',
'jardă',
'jarde',
'jaret',
'jarul',
'javei',
'javra',
'javră',
'javre',
'jazul',
'jăcaș',
'jăcii',
'jălbi',
'jăpși',
'jărci',
'jărea',
'jării',
'jărim',
'jărit',
'jărui',
'jderi',
'jeans',
'jebul',
'jecui',
'jefui',
'jegos',
'jegul',
'jejun',
'jelea',
'jelei',
'jeler',
'jeleu',
'jelii',
'jelim',
'jelit',
'jelui',
'jenai',
'jenam',
'jenat',
'jenau',
'jenăm',
'jenei',
'jenez',
'jepci',
'jepii',
'jepul',
'jerba',
'jerbă',
'jerbe',
'jerse',
'jerui',
'jetaj',
'jeteu',
'jeton',
'jetou',
'jetul',
'jețul',
'jiana',
'jiană',
'jibei',
'jibii',
'jibul',
'jicni',
'jidan',
'jidov',
'jiene',
'jieni',
'jigla',
'jiglă',
'jigle',
'jigni',
'jigou',
'jigul',
'jihad',
'jilav',
'jilip',
'jimba',
'jimbă',
'jipan',
'jipat',
'jipii',
'jipul',
'jirav',
'jirul',
'jitar',
'jitia',
'jitie',
'jitii',
'jneap',
'jnepi',
'joaca',
'joacă',
'joace',
'joasa',
'joasă',
'joase',
'joben',
'jobul',
'jocot',
'jocul',
'jocuț',
'jofra',
'jofră',
'jofre',
'joiai',
'joiam',
'joian',
'joiau',
'joile',
'joind',
'joiră',
'joire',
'joiri',
'joise',
'joiși',
'joita',
'joită',
'joite',
'joiți',
'jojei',
'joker',
'jonca',
'joncă',
'jonci',
'josul',
'joșii',
'jotei',
'joule',
'jouli',
'jubee',
'jubeu',
'jucai',
'jucam',
'jucat',
'jucau',
'jucăm',
'judec',
'județ',
'jugal',
'jugan',
'jugăr',
'jugul',
'juice',
'juisa',
'juisă',
'jujău',
'jujeu',
'jujub',
'julea',
'julep',
'julfa',
'julfă',
'julfe',
'julii',
'julim',
'julit',
'jumbo',
'junca',
'juncă',
'junci',
'junei',
'junel',
'junia',
'junie',
'junii',
'junta',
'juntă',
'junte',
'jupan',
'jupăi',
'jupân',
'jupea',
'jupei',
'jupii',
'jupim',
'jupit',
'jupoi',
'jupon',
'jupui',
'jurai',
'juram',
'jurat',
'jurau',
'jurăm',
'jurii',
'juriu',
'jurui',
'jurul',
'justa',
'justă',
'juste',
'juști',
'juvăț',
'juxta',
'juxtă',
'juxte',
'juzii',
'kabil',
'kagul',
'kakia',
'kakie',
'kakii',
'kakiu',
'kalam',
'kaliu',
'kamei',
'kamii',
'kaoni',
'kappa',
'karma',
'karme',
'kavei',
'kazah',
'kazoo',
'keaul',
'kediv',
'kenaf',
'kendo',
'kerii',
'kerul',
'ketch',
'ketei',
'kevir',
'khmer',
'kiang',
'kilul',
'kinas',
'kinei',
'kipii',
'kipul',
'kitul',
'koala',
'kobii',
'kobul',
'kodak',
'kogai',
'koine',
'korea',
'korei',
'kovar',
'kraal',
'kraft',
'krill',
'kubei',
'kudul',
'kulan',
'kumel',
'kurda',
'kurdă',
'kurde',
'kurzi',
'kyați',
'kyrie',
'laban',
'labei',
'label',
'labia',
'labie',
'labii',
'labil',
'labio',
'labiu',
'labri',
'labru',
'labul',
'lacăt',
'lacom',
'lacra',
'lacră',
'lacre',
'lacși',
'lacti',
'lacto',
'lacul',
'ladin',
'laful',
'lagăr',
'lagon',
'lagum',
'lahar',
'laica',
'laică',
'laice',
'laici',
'laiei',
'laița',
'laiță',
'laițe',
'laiul',
'lalea',
'lamai',
'lamam',
'lamat',
'lamau',
'lamăm',
'lamba',
'lambă',
'lambe',
'lamei',
'lamez',
'lampa',
'lampă',
'lance',
'landa',
'landă',
'lande',
'lanei',
'lansa',
'lansă',
'lanul',
'lapăd',
'lapid',
'lapin',
'lapis',
'lapit',
'lapon',
'lapte',
'lapți',
'larea',
'larga',
'largă',
'largi',
'largo',
'larii',
'larma',
'larmă',
'larme',
'larul',
'larva',
'larvă',
'larve',
'larvi',
'laser',
'lasou',
'lașai',
'lașam',
'lașat',
'lașau',
'lașăm',
'lașei',
'lașez',
'lașii',
'lașul',
'latei',
'later',
'lateș',
'latex',
'latin',
'latir',
'latră',
'latre',
'latri',
'latru',
'latul',
'lații',
'lațul',
'lauda',
'laudă',
'laude',
'lauri',
'lauzi',
'lavaj',
'lavei',
'laviu',
'lavra',
'lavră',
'lavre',
'laxei',
'laxul',
'lazul',
'lazzi',
'lăbos',
'lăcar',
'lăcaș',
'lăcat',
'lăcui',
'lădoi',
'lăfăi',
'lăieș',
'lăieț',
'lăile',
'lălăi',
'lălâi',
'lălâu',
'lămâi',
'lămpi',
'lănci',
'lăpăi',
'lărgi',
'lării',
'lăsai',
'lăsam',
'lăsat',
'lăsau',
'lăsăm',
'lătoc',
'lătra',
'lătră',
'lătur',
'lățea',
'lății',
'lățim',
'lățiș',
'lățit',
'lățos',
'lăuda',
'lăudă',
'lăură',
'lăuse',
'lăuși',
'lăuta',
'lăută',
'lăute',
'lăuți',
'lăuza',
'lăuză',
'lăuze',
'lăuzi',
'lăzii',
'lăzui',
'lângă',
'lânii',
'lânos',
'leacă',
'leafa',
'leafă',
'leagă',
'leaha',
'leahă',
'leala',
'leală',
'leale',
'leali',
'leasa',
'leasă',
'lebăr',
'lebes',
'lecii',
'lecit',
'lecui',
'lecuț',
'ledul',
'lefii',
'lefți',
'legai',
'legal',
'legam',
'legat',
'legau',
'legăm',
'legăn',
'legea',
'leghe',
'legic',
'legii',
'legul',
'lehăi',
'lehău',
'leica',
'leică',
'leici',
'leita',
'leită',
'leite',
'leița',
'leiță',
'leițe',
'leiți',
'lejer',
'lekul',
'lelea',
'lelei',
'leleu',
'lelii',
'lemei',
'lemne',
'lemul',
'lenaj',
'lenci',
'lenea',
'lenei',
'leneș',
'lenii',
'lenim',
'lenit',
'lenos',
'lenta',
'lentă',
'lente',
'lento',
'lenți',
'leone',
'leoni',
'lepăd',
'lepăi',
'lepra',
'lepră',
'lepre',
'lepșe',
'lepși',
'lepta',
'lepte',
'lepto',
'lepui',
'leroi',
'lerui',
'lerul',
'lesei',
'lesne',
'lesoi',
'lesta',
'lestă',
'leșei',
'leșia',
'leșie',
'leșii',
'leșin',
'lește',
'leșui',
'leșul',
'letal',
'letca',
'letcă',
'letci',
'leton',
'leții',
'leuaș',
'leuca',
'leucă',
'leuci',
'leuco',
'leuți',
'levei',
'levit',
'lexem',
'lexia',
'lexic',
'lexie',
'lexii',
'lezai',
'lezam',
'lezat',
'lezau',
'lezăm',
'lezez',
'liaje',
'liana',
'liană',
'liane',
'liant',
'liară',
'liase',
'liași',
'liata',
'liată',
'liate',
'liați',
'liaza',
'liază',
'liaze',
'libel',
'liber',
'libov',
'licăr',
'licee',
'liceu',
'licit',
'licur',
'lider',
'lidic',
'lieno',
'liere',
'lieri',
'lieze',
'liezi',
'lifta',
'liftă',
'lifte',
'ligav',
'ligii',
'ligni',
'ligno',
'ligul',
'ligur',
'lihăi',
'lihni',
'liind',
'liman',
'limax',
'limba',
'limbă',
'limbi',
'limei',
'limen',
'limes',
'limfa',
'limfă',
'limfe',
'limfo',
'limin',
'limni',
'limno',
'limon',
'limul',
'limus',
'linca',
'linei',
'liner',
'lingă',
'linge',
'lingi',
'linia',
'linie',
'linii',
'linio',
'links',
'linon',
'linou',
'linsa',
'linsă',
'linse',
'linșa',
'linșă',
'linși',
'linte',
'linți',
'linul',
'liota',
'liotă',
'liote',
'lipan',
'lipăi',
'lipcă',
'lipea',
'lipei',
'lipia',
'lipie',
'lipii',
'lipim',
'lipit',
'lipom',
'lipsa',
'lipsă',
'lipse',
'lipsi',
'lipul',
'lirat',
'lirei',
'liric',
'lisai',
'lisam',
'lisat',
'lisau',
'lisăm',
'lisei',
'lisez',
'lista',
'listă',
'liste',
'lisul',
'lișii',
'litia',
'litic',
'litie',
'litii',
'litiu',
'litou',
'litra',
'litră',
'litre',
'litri',
'litru',
'liței',
'liude',
'liuzi',
'livan',
'livid',
'livra',
'livră',
'livre',
'lizai',
'lizam',
'lizat',
'lizau',
'lizăm',
'lizei',
'lizez',
'lizol',
'loaza',
'loază',
'loaze',
'lobai',
'lobam',
'lobar',
'lobat',
'lobau',
'lobăm',
'lobby',
'lobda',
'lobdă',
'lobde',
'lobez',
'lobii',
'lobul',
'local',
'locaș',
'locei',
'locel',
'locii',
'locma',
'locui',
'locul',
'locus',
'lodba',
'lodbă',
'lodbe',
'loden',
'loess',
'lofai',
'lofam',
'lofat',
'lofau',
'lofăm',
'lofez',
'logia',
'logic',
'logie',
'logii',
'logos',
'lohan',
'lohii',
'lohio',
'loial',
'lojei',
'lojii',
'lombe',
'lomul',
'longi',
'lonja',
'lonjă',
'lonje',
'loran',
'lorar',
'lorii',
'loris',
'lorzi',
'lotca',
'lotcă',
'lotci',
'lotei',
'loton',
'lotri',
'lotru',
'lotul',
'lotus',
'loure',
'lovea',
'lovii',
'lovim',
'lovit',
'lozia',
'lozie',
'lozii',
'lozul',
'luară',
'luare',
'luase',
'luași',
'luata',
'luată',
'luate',
'luați',
'luări',
'luând',
'lucea',
'lucia',
'lucid',
'lucie',
'lucii',
'lucim',
'luciș',
'lucit',
'luciu',
'lucra',
'lucră',
'lucre',
'lucri',
'lucru',
'lucși',
'ludea',
'ludei',
'ludic',
'ludul',
'lueta',
'luetă',
'luete',
'lufar',
'lufei',
'lufta',
'luftă',
'lugol',
'lujer',
'lulea',
'lumea',
'lumen',
'lumeț',
'lumii',
'lunar',
'lunca',
'luncă',
'lunch',
'lunci',
'lunea',
'lunec',
'lunga',
'lungă',
'lungi',
'lunic',
'lunii',
'lupan',
'lupei',
'lupia',
'lupic',
'lupie',
'lupii',
'lupin',
'lupiu',
'lupoi',
'lupom',
'lupon',
'lupta',
'luptă',
'lupte',
'lupți',
'lupul',
'lupus',
'lurex',
'lusin',
'lușai',
'lușam',
'lușat',
'lușau',
'lușăm',
'lușei',
'lușez',
'lușii',
'lușul',
'luteo',
'lutos',
'lutra',
'lutră',
'lutre',
'lutru',
'lutui',
'lutul',
'luxai',
'luxam',
'luxat',
'luxau',
'luxăm',
'luxez',
'luxos',
'luxul',
'luzii',
'lycra',
'macac',
'macat',
'macaz',
'mache',
'machi',
'macho',
'macii',
'macin',
'macla',
'maclă',
'macle',
'macra',
'macră',
'macre',
'macri',
'macro',
'macru',
'macul',
'madam',
'madea',
'mafia',
'mafie',
'mafii',
'magia',
'magic',
'magie',
'magii',
'magma',
'magmă',
'magme',
'magna',
'magni',
'magno',
'magot',
'magul',
'mahăr',
'mahon',
'mahut',
'maial',
'maica',
'maică',
'maice',
'maici',
'maiei',
'maieu',
'maina',
'maină',
'maior',
'maiou',
'maișa',
'maișă',
'maișe',
'maiul',
'major',
'majur',
'makii',
'malac',
'malar',
'malic',
'malin',
'malul',
'mamar',
'mamba',
'mambo',
'mamei',
'mamon',
'mamoș',
'mamut',
'manaf',
'manca',
'mancă',
'mance',
'manco',
'manea',
'manei',
'manej',
'mango',
'mania',
'manie',
'manii',
'manșa',
'manșă',
'manșe',
'manta',
'mantă',
'mante',
'manul',
'maori',
'mapei',
'maran',
'maraz',
'marca',
'marcă',
'marda',
'mardi',
'marea',
'maree',
'marfa',
'marfă',
'marga',
'margă',
'marge',
'maria',
'marie',
'marii',
'marin',
'marja',
'marjă',
'marje',
'marna',
'marnă',
'marne',
'maron',
'marșa',
'marșă',
'marșe',
'marte',
'marți',
'masai',
'masaj',
'masam',
'masat',
'masau',
'masăm',
'masca',
'mască',
'masei',
'maser',
'masez',
'masic',
'masiv',
'masla',
'maslă',
'masle',
'maslu',
'mason',
'masor',
'masta',
'mastă',
'maste',
'masto',
'masul',
'mașai',
'mașam',
'mașat',
'mașau',
'mașăm',
'mașez',
'mașii',
'matai',
'matam',
'matat',
'matau',
'matăm',
'matca',
'matcă',
'matei',
'mater',
'matez',
'matol',
'mator',
'matri',
'matro',
'matuf',
'matul',
'matur',
'mații',
'mațul',
'maura',
'maură',
'maure',
'mauri',
'maxim',
'maxit',
'mayas',
'mayei',
'mazâl',
'mazil',
'mazul',
'mazur',
'mazut',
'măcar',
'măcăi',
'măcău',
'măcel',
'măceș',
'măgan',
'măgar',
'măiug',
'măiuț',
'măjar',
'măjer',
'măjii',
'mălai',
'mălin',
'măluț',
'mămos',
'mănat',
'mănau',
'mănos',
'mărar',
'măraz',
'mărci',
'mărea',
'măreț',
'mărfi',
'măria',
'mărie',
'mării',
'mărim',
'mărit',
'măroi',
'mărul',
'măruț',
'măsai',
'măsar',
'măsea',
'măsei',
'măsoi',
'măsor',
'măști',
'mătci',
'mătur',
'mățar',
'mâcăi',
'mâché',
'mâcii',
'mâgla',
'mâglă',
'mâgle',
'mâhni',
'mâind',
'mâine',
'mâini',
'mâlci',
'mâlea',
'mâlii',
'mâlim',
'mâlit',
'mâlos',
'mâlul',
'mânai',
'mânam',
'mânaș',
'mânat',
'mânau',
'mânăm',
'mânca',
'mâncă',
'mânce',
'mânci',
'mânea',
'mânec',
'mânem',
'mâner',
'mânia',
'mânie',
'mânii',
'mânji',
'mânui',
'mânza',
'mânză',
'mânze',
'mârâi',
'mârei',
'mârli',
'mâșâi',
'mâtca',
'mâtcă',
'mâtci',
'mâțan',
'mâței',
'mâții',
'mâțoi',
'mâțuc',
'mâțul',
'mâzga',
'mâzgă',
'mâzgi',
'meară',
'meato',
'mecăi',
'mecet',
'media',
'medic',
'medie',
'medii',
'medio',
'mediu',
'medul',
'mefia',
'mefie',
'megal',
'mehăi',
'meiul',
'mejda',
'mejdă',
'mejde',
'melan',
'melci',
'meleu',
'melia',
'melic',
'melii',
'meliț',
'melod',
'meloe',
'melon',
'melos',
'melul',
'menaj',
'menea',
'menii',
'menim',
'menit',
'meniu',
'menou',
'menta',
'mentă',
'mente',
'menur',
'merar',
'merei',
'merem',
'mereu',
'merge',
'mergi',
'merii',
'merit',
'merlu',
'merse',
'mersi',
'merși',
'merul',
'mesaj',
'mesaș',
'mesea',
'mesei',
'mesia',
'mesii',
'mesim',
'mesit',
'mesto',
'meșei',
'meșii',
'meșul',
'metal',
'metan',
'metec',
'meteo',
'metil',
'metis',
'metoc',
'metod',
'metoh',
'metol',
'metop',
'metri',
'metro',
'metru',
'mezat',
'mezel',
'mezii',
'mezin',
'mezon',
'mezzo',
'miala',
'mială',
'miare',
'miaua',
'miaul',
'miaun',
'miaut',
'miaza',
'miază',
'miaze',
'micea',
'micei',
'micet',
'micii',
'micim',
'micit',
'micro',
'micul',
'micuț',
'midia',
'midie',
'midii',
'mieii',
'mieji',
'miele',
'mielo',
'miere',
'mieri',
'miezi',
'migăi',
'migma',
'migmă',
'migme',
'migra',
'migră',
'miile',
'miime',
'miimi',
'mijea',
'mijii',
'mijim',
'mijit',
'milan',
'milei',
'milog',
'milos',
'milui',
'milul',
'mimai',
'mimam',
'mimat',
'mimau',
'mimăm',
'mimei',
'mimez',
'mimic',
'mimii',
'mimul',
'minai',
'minaj',
'minam',
'minat',
'minau',
'minăm',
'minei',
'miner',
'minez',
'minge',
'mingi',
'minia',
'minie',
'minim',
'miniu',
'minor',
'minta',
'mintă',
'minte',
'mință',
'minți',
'minus',
'minut',
'mione',
'mioni',
'miopi',
'miorc',
'miori',
'mioza',
'mioză',
'mioze',
'mirai',
'miraj',
'miram',
'miras',
'mirat',
'mirau',
'miraz',
'mirăm',
'mirei',
'mirel',
'mirez',
'mirha',
'mirhă',
'mirhe',
'miria',
'mirie',
'mirii',
'miros',
'mirți',
'mirui',
'mirul',
'misei',
'misia',
'misie',
'misii',
'misil',
'misir',
'misit',
'mișca',
'mișcă',
'mișea',
'mișei',
'mișel',
'miște',
'miști',
'mișto',
'mișui',
'mișun',
'mitei',
'mitel',
'mitic',
'mitoc',
'mitra',
'mitră',
'mitre',
'mitui',
'mitul',
'mițea',
'miței',
'miții',
'mițim',
'mițit',
'mițos',
'mițui',
'miuon',
'mixai',
'mixaj',
'mixam',
'mixat',
'mixau',
'mixăm',
'mixer',
'mixez',
'mixia',
'mixie',
'mixii',
'mixom',
'mixta',
'mixtă',
'mixte',
'mizai',
'mizam',
'mizat',
'mizau',
'mizăm',
'mizei',
'mizer',
'mizez',
'mizid',
'mlaca',
'mlacă',
'mlada',
'mladă',
'mlaja',
'mlajă',
'mlăci',
'mlăji',
'mlăzi',
'mnema',
'mnemă',
'mneme',
'mnemo',
'moaca',
'moacă',
'moace',
'moaie',
'moale',
'moara',
'moară',
'moare',
'moașa',
'moașă',
'moașe',
'moața',
'moață',
'moațe',
'moaul',
'moaza',
'moază',
'moaze',
'mobil',
'mocan',
'mocăi',
'mocea',
'mocii',
'mocim',
'mocit',
'mocni',
'mocul',
'modal',
'modei',
'model',
'modem',
'moder',
'modic',
'modre',
'modru',
'modul',
'modus',
'mogul',
'mohor',
'moile',
'moima',
'moimă',
'moime',
'moina',
'moină',
'moine',
'mojar',
'mojic',
'molai',
'molal',
'molam',
'molan',
'molar',
'molat',
'molau',
'molăm',
'molâi',
'molâu',
'molda',
'moldă',
'molde',
'moldo',
'molei',
'molet',
'molez',
'molia',
'molid',
'molie',
'molii',
'moloh',
'molon',
'molos',
'moloz',
'molto',
'molul',
'momâi',
'momea',
'momii',
'momim',
'momit',
'monac',
'mondo',
'monem',
'mongo',
'monic',
'monom',
'monta',
'montă',
'monte',
'mopși',
'mopul',
'moral',
'morar',
'morav',
'morei',
'morfo',
'morga',
'morgă',
'morgi',
'moria',
'morii',
'morna',
'mornă',
'morne',
'morni',
'moroi',
'moron',
'morsa',
'morsă',
'morse',
'morți',
'morua',
'moruă',
'morue',
'morun',
'morva',
'morvă',
'morve',
'mosor',
'mosso',
'moșan',
'moșea',
'moșia',
'moșic',
'moșie',
'moșii',
'moșim',
'moșit',
'moști',
'moșuc',
'moșul',
'motan',
'motca',
'motcă',
'motci',
'motel',
'motet',
'motiv',
'motoc',
'motor',
'motto',
'moțai',
'moțam',
'moțat',
'moțau',
'moțăi',
'moțăm',
'moțez',
'moții',
'moțoc',
'moțul',
'mouse',
'movul',
'moxei',
'mozan',
'mozoc',
'mozol',
'mreja',
'mrejă',
'mreje',
'mreji',
'mrene',
'mucar',
'mucea',
'muced',
'muche',
'muchi',
'mucii',
'mucor',
'mucos',
'mucul',
'mucus',
'mudir',
'mufei',
'mufla',
'muflă',
'mufle',
'mugea',
'muget',
'mugii',
'mugim',
'mugit',
'mugur',
'muhur',
'muiai',
'muiaj',
'muiam',
'muiat',
'muiau',
'muica',
'muică',
'muici',
'muiem',
'muind',
'mujic',
'mulai',
'mulaj',
'mulam',
'mulat',
'mulau',
'mulăm',
'mulei',
'mulez',
'mulgă',
'mulge',
'mulgi',
'mulii',
'mulsa',
'mulsă',
'mulse',
'mulși',
'multa',
'multă',
'multe',
'multi',
'mulți',
'mulul',
'mumei',
'mumia',
'mumie',
'mumii',
'munca',
'muncă',
'munci',
'munda',
'mundi',
'mungo',
'munte',
'munți',
'murai',
'mural',
'muram',
'murat',
'murau',
'murăm',
'murea',
'murei',
'murex',
'murez',
'murga',
'murgă',
'murgi',
'murid',
'murii',
'murim',
'murin',
'muriș',
'murit',
'mursa',
'mursă',
'murse',
'murui',
'murul',
'musai',
'musat',
'musau',
'musca',
'muscă',
'musci',
'musei',
'musiu',
'müsli',
'muson',
'musti',
'musul',
'mușca',
'mușcă',
'mușii',
'muște',
'muști',
'mutai',
'mutam',
'mutat',
'mutau',
'mutăm',
'mutei',
'mutic',
'muton',
'mutra',
'mutră',
'mutre',
'mutul',
'muțea',
'muția',
'muție',
'muții',
'muțim',
'muțit',
'muțiu',
'muzee',
'muzei',
'muzeo',
'muzeu',
'muzic',
'nabab',
'nabor',
'nacru',
'nadei',
'nadel',
'nadir',
'nadol',
'nafea',
'nafta',
'naftă',
'nafte',
'nagâț',
'nahur',
'naiba',
'naica',
'naică',
'naici',
'naiei',
'naira',
'naire',
'naist',
'naiul',
'naiva',
'naivă',
'naive',
'naivi',
'najna',
'najnă',
'najne',
'nalba',
'nalbă',
'nalbe',
'nalta',
'naltă',
'nalte',
'nalță',
'nalțe',
'nalți',
'namaz',
'nandu',
'nanei',
'nanii',
'nanul',
'napai',
'napam',
'napat',
'napau',
'napăm',
'napez',
'napii',
'napul',
'narai',
'naram',
'narat',
'narau',
'narăm',
'narco',
'narea',
'narez',
'narnă',
'nască',
'nasol',
'nasul',
'nașei',
'nașii',
'naște',
'naști',
'nașul',
'natal',
'nativ',
'natra',
'natră',
'natre',
'natul',
'natur',
'nația',
'nație',
'nații',
'nauto',
'naval',
'navei',
'navlu',
'nazal',
'nazar',
'nazâr',
'nazir',
'nazul',
'năboi',
'năbuc',
'năbuș',
'năcaz',
'nădăi',
'năduf',
'năduh',
'năhut',
'năier',
'năile',
'năimi',
'năjea',
'năjii',
'năjim',
'năjit',
'nălbi',
'nălța',
'nălță',
'nămea',
'nămii',
'nămim',
'nămit',
'nămol',
'nănaș',
'nărav',
'nării',
'nărod',
'nărui',
'născu',
'năsip',
'năsli',
'năsoi',
'năsos',
'năsuc',
'năsui',
'năsut',
'năsuț',
'nășea',
'nășei',
'nășel',
'nășia',
'nășic',
'nășie',
'nășii',
'nășim',
'nășit',
'năuca',
'năucă',
'năuce',
'năuci',
'năvod',
'năzar',
'năzii',
'năzui',
'nânaș',
'neaga',
'neagă',
'neamț',
'neant',
'neaoș',
'neaua',
'neauă',
'nează',
'nebun',
'necaz',
'necro',
'necum',
'nedei',
'neder',
'nefei',
'nefel',
'nefer',
'nefla',
'neflă',
'nefle',
'nefro',
'negai',
'negam',
'negat',
'negau',
'negăm',
'negei',
'negel',
'negii',
'negos',
'negoț',
'negre',
'negri',
'negro',
'negru',
'negui',
'negul',
'negus',
'neica',
'neică',
'neios',
'nemat',
'nemeș',
'nemet',
'nemic',
'nemți',
'nemuț',
'nenea',
'nenei',
'nenia',
'nenic',
'nenie',
'nenii',
'nenuț',
'nepei',
'neper',
'nepot',
'nepus',
'neras',
'nerod',
'nervi',
'nesaț',
'nesul',
'nește',
'neted',
'netei',
'netez',
'netot',
'netul',
'neții',
'nețul',
'neuma',
'neumă',
'neume',
'neuri',
'neuro',
'neveu',
'nevia',
'nevie',
'nevii',
'neviu',
'nevod',
'nevoi',
'nevri',
'nevro',
'nevul',
'nexul',
'nicio',
'nicol',
'nicti',
'nicto',
'nidus',
'niela',
'nielă',
'niele',
'nimba',
'nimbă',
'nimfa',
'nimfă',
'nimfe',
'nimfo',
'nimic',
'ningă',
'ninge',
'ninja',
'ninsa',
'ninsă',
'ninse',
'ninși',
'niper',
'niplu',
'nipon',
'nisip',
'nișei',
'niște',
'nitra',
'nitră',
'nitro',
'nitui',
'nitul',
'niței',
'nițel',
'niții',
'nival',
'nivel',
'nixei',
'nixis',
'nizam',
'noada',
'noadă',
'noade',
'nobil',
'nociv',
'nocti',
'nodal',
'nodos',
'nodul',
'noema',
'noemă',
'noeme',
'noeza',
'noeză',
'noeze',
'nogai',
'noian',
'noile',
'noima',
'noimă',
'noime',
'noimi',
'noița',
'noiță',
'noițe',
'nomad',
'nomei',
'nomol',
'nomul',
'nonei',
'nonet',
'noneu',
'nopai',
'nopal',
'nopam',
'nopat',
'nopau',
'nopăm',
'nopeu',
'nopez',
'nopți',
'norea',
'norei',
'norii',
'norit',
'norma',
'normă',
'norme',
'normo',
'noroc',
'norod',
'noroi',
'noros',
'norul',
'noruț',
'noști',
'notai',
'notam',
'notar',
'notat',
'notau',
'notăm',
'notei',
'notes',
'notez',
'nouar',
'nouăi',
'noura',
'noură',
'nouri',
'nouța',
'nouță',
'nouțe',
'nouți',
'novac',
'novai',
'novam',
'novat',
'novau',
'novăm',
'novei',
'novez',
'noxei',
'nubil',
'nubuc',
'nucal',
'nucar',
'nucet',
'nucii',
'nucle',
'nucul',
'nucuț',
'nudei',
'nudul',
'nufăr',
'nulei',
'nulii',
'nulul',
'numai',
'număr',
'numea',
'numen',
'numii',
'numim',
'numit',
'nunei',
'nunii',
'nunta',
'nuntă',
'nunti',
'nunți',
'nunul',
'nuori',
'nurca',
'nurcă',
'nurci',
'nurii',
'nursa',
'nursă',
'nurse',
'nurul',
'nutri',
'nutui',
'nutul',
'nuzii',
'nyaya',
'nyaye',
'oabla',
'oablă',
'oable',
'oacăn',
'oacăr',
'oagei',
'oalei',
'oarba',
'oarbă',
'oarbe',
'oarda',
'oardă',
'oarde',
'oarei',
'oarze',
'oaspe',
'oaste',
'oașei',
'oazei',
'obada',
'obadă',
'obaht',
'obăda',
'obădă',
'obări',
'obela',
'obelă',
'obele',
'oberi',
'obeza',
'obeză',
'obeze',
'obezi',
'obida',
'obidă',
'obide',
'obidi',
'obiul',
'oblat',
'oblea',
'obleț',
'oblic',
'oblig',
'oblii',
'oblim',
'oblit',
'oblon',
'oblul',
'oboli',
'obora',
'obori',
'oborî',
'obosi',
'obote',
'obraț',
'obraz',
'obroc',
'obron',
'obște',
'obști',
'obtuz',
'obție',
'obții',
'obțin',
'obțiu',
'obuze',
'ocale',
'ocara',
'ocară',
'ocaua',
'ocăia',
'ocăie',
'ocăit',
'ocăle',
'ocăra',
'ocări',
'ocărî',
'ocean',
'oceli',
'ochea',
'ochet',
'ocheț',
'ochii',
'ochim',
'ochit',
'ochlo',
'ocile',
'ocina',
'ocină',
'ocini',
'oclus',
'ocnaș',
'ocnei',
'ocoli',
'ocrul',
'octal',
'octan',
'octet',
'oculo',
'ocult',
'ocupa',
'ocupă',
'ocupe',
'ocupi',
'ocurg',
'ocurs',
'odaia',
'odaie',
'odată',
'odăii',
'odele',
'odeon',
'odgon',
'odina',
'odină',
'odine',
'odini',
'odino',
'odios',
'odiul',
'odont',
'odori',
'oesar',
'oferă',
'ofere',
'oferi',
'offul',
'ofili',
'ofise',
'ofism',
'ofiur',
'ofset',
'oftai',
'oftam',
'oftat',
'oftau',
'oftăm',
'oftez',
'oftic',
'oftig',
'oftul',
'ofuri',
'ofusc',
'ogari',
'ogeac',
'ogeag',
'ogece',
'ogege',
'ogiva',
'ogivă',
'ogive',
'ogoia',
'ogoie',
'ogoii',
'ogoim',
'ogoit',
'ogora',
'ogorî',
'ohaba',
'ohabă',
'ohabe',
'ohmic',
'ohmii',
'ohmul',
'oiaga',
'oiagă',
'oidea',
'oidia',
'oidie',
'oidii',
'oiegi',
'oieri',
'oiesc',
'oilor',
'oinei',
'oiște',
'oiști',
'oiței',
'ojele',
'ojina',
'ojină',
'ojine',
'ojini',
'ojogi',
'okapi',
'okume',
'olace',
'olaci',
'olana',
'olană',
'olane',
'olani',
'olari',
'olate',
'olcar',
'oleab',
'oleat',
'oleia',
'oleic',
'oleie',
'oleom',
'oleul',
'oleum',
'oligo',
'olița',
'oliță',
'olițe',
'oliva',
'olivă',
'olive',
'olmaz',
'olmii',
'olmul',
'ologi',
'oloia',
'oloii',
'oloim',
'oloit',
'olori',
'olozi',
'olpei',
'oltar',
'oluri',
'oluțe',
'omagi',
'ombro',
'omega',
'omeni',
'oment',
'omeți',
'omfal',
'omida',
'omidă',
'omisa',
'omisă',
'omise',
'omiși',
'omită',
'omite',
'omiți',
'omizi',
'omnia',
'omoii',
'omora',
'omori',
'omorî',
'onest',
'onico',
'oniro',
'onoma',
'onora',
'onoră',
'onori',
'ontic',
'onuri',
'oocit',
'oofit',
'oofor',
'oogon',
'oolit',
'oozom',
'opaca',
'opacă',
'opace',
'opaci',
'opaiț',
'opale',
'opăci',
'opări',
'opera',
'operă',
'opere',
'opiat',
'opina',
'opină',
'opise',
'opist',
'opiul',
'opium',
'oprea',
'opreg',
'oprii',
'oprim',
'oprit',
'opsas',
'opșpe',
'optai',
'optam',
'optar',
'optat',
'optau',
'optăm',
'optez',
'optic',
'optim',
'optul',
'opuie',
'opună',
'opune',
'opuri',
'opusa',
'opusă',
'opuse',
'opust',
'opuși',
'oraje',
'orala',
'orală',
'orale',
'orali',
'oranj',
'orant',
'oranz',
'orara',
'orară',
'orare',
'orari',
'orașe',
'orând',
'orbea',
'orbec',
'orbeț',
'orbia',
'orbie',
'orbii',
'orbim',
'orbiș',
'orbit',
'orbul',
'orcan',
'orcăi',
'orcic',
'orcul',
'ordia',
'ordie',
'ordii',
'ordin',
'ordon',
'oreav',
'orele',
'orexi',
'orfan',
'orfic',
'organ',
'orgia',
'orgie',
'orgii',
'orice',
'oriei',
'orier',
'orile',
'orjad',
'orlon',
'ornai',
'ornam',
'ornat',
'ornau',
'ornăm',
'ornez',
'ornic',
'orori',
'orșag',
'ortac',
'ortic',
'ortul',
'orții',
'orzar',
'orzul',
'osana',
'osari',
'oscar',
'oscei',
'oscii',
'oscil',
'oscul',
'osebi',
'oseta',
'osetă',
'osete',
'oseți',
'osiei',
'osman',
'osmic',
'osmiu',
'osmol',
'osoși',
'ospăț',
'ospeț',
'osram',
'ossia',
'ossii',
'ostaș',
'osteo',
'ostia',
'ostic',
'ostie',
'ostii',
'ostil',
'ostoi',
'ostul',
'osuar',
'osuțe',
'oșean',
'oșene',
'oșeni',
'oștea',
'oștii',
'oștim',
'oștit',
'otace',
'otaje',
'otava',
'otavă',
'otave',
'otăvi',
'otcoș',
'otcup',
'otele',
'otfel',
'otgon',
'otice',
'otinc',
'otita',
'otită',
'otite',
'otova',
'oturi',
'oțăra',
'oțări',
'oțărî',
'oțele',
'oțeli',
'oțera',
'oțeri',
'oțerî',
'oțeti',
'oțeți',
'oțios',
'ouară',
'ouare',
'ouase',
'ouata',
'ouată',
'ouate',
'ouați',
'ouăle',
'ouări',
'ouând',
'ouleț',
'oului',
'oușor',
'ouțul',
'ovala',
'ovală',
'ovale',
'ovali',
'ovare',
'ovata',
'ovată',
'ovate',
'ovați',
'ovele',
'ovese',
'oveze',
'ovili',
'ovina',
'ovină',
'ovine',
'ovini',
'ovism',
'ovoid',
'ovrei',
'ovreu',
'ovule',
'oxali',
'oxalo',
'oxida',
'oxidă',
'oxido',
'oxiur',
'oxizi',
'ozele',
'ozena',
'ozenă',
'ozene',
'ozona',
'ozonă',
'pacea',
'pacte',
'pacul',
'padia',
'padie',
'padii',
'padoc',
'pafta',
'pagăi',
'pagei',
'pager',
'pagii',
'pagul',
'pagur',
'pahar',
'paici',
'paiet',
'paing',
'paiol',
'paion',
'pairi',
'paiul',
'pajii',
'pajul',
'palan',
'palat',
'palca',
'palcă',
'palce',
'palee',
'palei',
'paleo',
'palha',
'palhă',
'palhe',
'palia',
'palid',
'palie',
'palii',
'paliu',
'palma',
'palmă',
'palme',
'palmi',
'paloș',
'palpa',
'palpă',
'palpe',
'palpi',
'palul',
'paluș',
'palux',
'pamai',
'pamam',
'pamat',
'pamau',
'pamăm',
'pamez',
'pampa',
'pampe',
'panaș',
'panda',
'panel',
'paner',
'panii',
'panou',
'pansa',
'pansă',
'panta',
'pantă',
'pante',
'panul',
'panus',
'papal',
'papei',
'papii',
'papir',
'papua',
'papuc',
'papul',
'papus',
'parai',
'param',
'parat',
'parau',
'parăm',
'parca',
'parcă',
'parce',
'parei',
'parem',
'parez',
'parfe',
'paria',
'parie',
'parii',
'parip',
'pariu',
'paroh',
'paroi',
'parol',
'parom',
'parși',
'parta',
'partă',
'parte',
'party',
'parți',
'parul',
'pasai',
'pasaj',
'pasam',
'pasat',
'pasau',
'pasăm',
'pasca',
'pască',
'pasei',
'pasez',
'pasiv',
'pasta',
'pastă',
'paste',
'pasul',
'pașca',
'pașcă',
'pașei',
'pașii',
'pașli',
'pașol',
'paște',
'paști',
'pașuș',
'pater',
'pateu',
'patio',
'patma',
'patmă',
'patme',
'patos',
'patra',
'patru',
'patul',
'pauca',
'paucă',
'pauce',
'pauza',
'pauză',
'pauze',
'pavai',
'pavaj',
'pavam',
'pavat',
'pavau',
'pavăm',
'pavea',
'pavei',
'pavez',
'pazei',
'pazia',
'pazie',
'pazii',
'păbâi',
'păcat',
'păcii',
'păfug',
'păgân',
'păhar',
'păhăi',
'păiai',
'păiam',
'păiau',
'păier',
'păind',
'păios',
'păiră',
'păire',
'păiri',
'păise',
'păiși',
'păita',
'păită',
'păite',
'păiți',
'păiuș',
'păiuț',
'pălan',
'pălea',
'pălii',
'pălim',
'păliș',
'pălit',
'păpai',
'păpam',
'păpat',
'păpau',
'păpăm',
'păpuc',
'păraș',
'părău',
'părea',
'părem',
'păros',
'părți',
'părui',
'părul',
'părut',
'păsai',
'păsam',
'păsat',
'păsau',
'păsăm',
'păscu',
'păsui',
'păsul',
'pășea',
'pășii',
'pășim',
'pășin',
'pășit',
'păști',
'pătai',
'pătam',
'pătat',
'pătau',
'pătăm',
'pătez',
'pătuc',
'pătul',
'pătuț',
'pățea',
'pății',
'pățim',
'pățit',
'păuna',
'păună',
'păune',
'păuni',
'păzea',
'păzii',
'păzim',
'păzit',
'pâcăi',
'pâcâi',
'pâcla',
'pâclă',
'pâcle',
'pâine',
'pâini',
'pânda',
'pândă',
'pânde',
'pândi',
'pânza',
'pânză',
'pânze',
'pâpor',
'pârai',
'pâram',
'pârau',
'pârăi',
'pârău',
'pârâi',
'pârâm',
'pârâș',
'pârât',
'pârâu',
'pârei',
'pârga',
'pârgă',
'pârgi',
'pârii',
'pârli',
'pârși',
'pârte',
'pârți',
'pârul',
'pâsla',
'pâslă',
'pâsle',
'pâsli',
'pâșii',
'pâșin',
'pâșul',
'peana',
'peană',
'peane',
'pecia',
'pecie',
'pecii',
'peciu',
'pecum',
'pedea',
'pedel',
'pedes',
'pefug',
'pegas',
'pegra',
'pegră',
'pegre',
'pejma',
'pejmă',
'pejme',
'pelaj',
'peleș',
'pelin',
'pelit',
'pelur',
'pembe',
'penai',
'penaj',
'penal',
'penam',
'penar',
'penat',
'penau',
'penăm',
'penei',
'penel',
'penet',
'penez',
'penii',
'penin',
'penis',
'penny',
'pensa',
'pensă',
'pense',
'pente',
'penți',
'peoni',
'pepit',
'peplu',
'pepsi',
'peree',
'perei',
'peren',
'pereu',
'peria',
'perie',
'perii',
'periș',
'perja',
'perjă',
'perje',
'perji',
'perla',
'perlă',
'perle',
'perna',
'pernă',
'perne',
'peron',
'persa',
'persă',
'perse',
'perși',
'peruș',
'pesos',
'pesta',
'pestă',
'peste',
'pesti',
'peșim',
'peșin',
'pește',
'pești',
'petas',
'petea',
'petec',
'petei',
'petic',
'petit',
'pețea',
'peții',
'pețim',
'pețit',
'pfund',
'piane',
'piano',
'piară',
'piața',
'piață',
'piaza',
'piază',
'picai',
'picaj',
'picam',
'picat',
'picau',
'picăm',
'picii',
'picni',
'picol',
'picon',
'picot',
'picou',
'picta',
'pictă',
'picui',
'picul',
'picup',
'picur',
'picuș',
'picuț',
'pieii',
'piele',
'pielm',
'piemn',
'piept',
'pierd',
'piere',
'pieri',
'pierz',
'piesa',
'piesă',
'piese',
'pieta',
'piețe',
'pieze',
'piezi',
'pifan',
'pifei',
'pilaf',
'pilar',
'pilda',
'pildă',
'pilde',
'pilea',
'pilei',
'pilii',
'pilim',
'pilit',
'pilon',
'pilor',
'pilos',
'pilot',
'pilug',
'pilul',
'pinen',
'pinii',
'pinot',
'pința',
'pință',
'pințe',
'pinul',
'pioni',
'pioși',
'piota',
'piotă',
'piote',
'pipai',
'pipam',
'pipat',
'pipau',
'pipăi',
'pipăm',
'pipei',
'piper',
'pipez',
'pipit',
'pirat',
'pireu',
'pirex',
'piroi',
'pirol',
'piron',
'pirop',
'pirui',
'pirul',
'pisai',
'pisam',
'pisar',
'pisat',
'pisau',
'pisăm',
'piser',
'pisez',
'pisic',
'pisoi',
'pista',
'pistă',
'piste',
'pișai',
'pișam',
'pișat',
'pișau',
'pișăm',
'pișca',
'pișcă',
'piște',
'piști',
'pișul',
'pitac',
'pitan',
'pitar',
'pitea',
'pitei',
'pitic',
'pitii',
'pitim',
'pitiș',
'pitit',
'pitoi',
'piton',
'pitul',
'pițan',
'pițig',
'piuai',
'piuam',
'piuar',
'piuat',
'piuau',
'piuăm',
'piuei',
'piuez',
'piuia',
'piuie',
'piuit',
'piure',
'piuuu',
'pivei',
'pivot',
'pixul',
'pizma',
'pizmă',
'pizme',
'pizza',
'pizză',
'pizze',
'placa',
'placă',
'place',
'placi',
'plaga',
'plagă',
'plaie',
'plaja',
'plajă',
'plaje',
'plana',
'plană',
'plane',
'plani',
'plano',
'plasa',
'plasă',
'plase',
'plata',
'plată',
'plate',
'plați',
'plaur',
'plava',
'plavă',
'plave',
'plavi',
'plăci',
'plăcu',
'plăgi',
'plăși',
'plăti',
'plăți',
'plâng',
'plâns',
'plean',
'plebe',
'pleca',
'plecă',
'plece',
'pleci',
'pleda',
'pledă',
'plene',
'plese',
'pleșe',
'pleși',
'plete',
'pleul',
'pleve',
'pliai',
'pliaj',
'pliam',
'pliat',
'pliau',
'plica',
'plică',
'plice',
'plici',
'pliem',
'pliez',
'plimb',
'plina',
'plină',
'pline',
'plini',
'plisa',
'plisă',
'plisc',
'plise',
'plita',
'plită',
'plite',
'pliul',
'plivi',
'plodi',
'ploii',
'plopi',
'ploua',
'plouă',
'plozi',
'plumb',
'pluri',
'plusa',
'plusă',
'plușa',
'plușă',
'pluta',
'plută',
'plute',
'pluti',
'pneul',
'poala',
'poală',
'poale',
'poama',
'poamă',
'poame',
'poara',
'poară',
'poare',
'poată',
'poate',
'pocal',
'pocăi',
'pocea',
'pocia',
'pocie',
'pocii',
'pocim',
'pocit',
'pocni',
'pocoi',
'podan',
'podar',
'podea',
'podeț',
'podii',
'podim',
'podiș',
'podit',
'podul',
'poduț',
'poema',
'poemă',
'poeme',
'poeta',
'poetă',
'poete',
'poeți',
'pofil',
'pofta',
'poftă',
'pofte',
'pofti',
'pogan',
'pogon',
'pogor',
'pohar',
'pohod',
'pohoi',
'pohoț',
'pohta',
'pohtă',
'pohte',
'pohti',
'poise',
'pojar',
'poker',
'polar',
'polca',
'polcă',
'polci',
'polei',
'polen',
'polii',
'polip',
'polis',
'poliș',
'polje',
'polog',
'polon',
'poloș',
'polua',
'poluă',
'polul',
'pomăi',
'pomet',
'pomii',
'pompa',
'pompă',
'pompe',
'pomul',
'ponce',
'ponci',
'ponei',
'ponit',
'poniv',
'ponod',
'ponor',
'ponos',
'ponta',
'pontă',
'ponte',
'ponți',
'popas',
'popaz',
'popâc',
'popea',
'popei',
'popia',
'popic',
'popie',
'popii',
'popim',
'popit',
'popol',
'popor',
'popou',
'popri',
'popul',
'porci',
'porif',
'porii',
'porni',
'porno',
'poros',
'porta',
'portă',
'porte',
'porto',
'porți',
'porul',
'posac',
'posed',
'posta',
'postă',
'poste',
'posti',
'poșta',
'poștă',
'poște',
'poșul',
'potăi',
'potca',
'potcă',
'potce',
'potir',
'potop',
'potou',
'potul',
'pound',
'povăț',
'povod',
'povoi',
'poxia',
'poxie',
'poxii',
'pozai',
'pozam',
'pozat',
'pozau',
'pozăm',
'pozei',
'pozez',
'pozna',
'poznă',
'pozne',
'prada',
'pradă',
'prade',
'praji',
'prașa',
'prașă',
'prașe',
'praxa',
'praxă',
'praxe',
'prazi',
'prăci',
'prăda',
'prădă',
'prăde',
'prăji',
'prăsi',
'prăși',
'prăzi',
'prânz',
'preda',
'predă',
'prefă',
'preia',
'preot',
'presa',
'presă',
'prese',
'preta',
'pretă',
'prezi',
'prian',
'priau',
'prica',
'prică',
'price',
'prici',
'prier',
'priit',
'prima',
'primă',
'prime',
'primi',
'primo',
'prind',
'prins',
'print',
'prinț',
'prinz',
'priod',
'prior',
'pripa',
'pripă',
'pripe',
'pripi',
'priva',
'privă',
'privi',
'priza',
'priză',
'prize',
'proba',
'probă',
'probe',
'probi',
'proci',
'produ',
'proin',
'prois',
'proor',
'prora',
'proră',
'prore',
'prost',
'proto',
'prour',
'prova',
'provă',
'prove',
'proza',
'proză',
'proze',
'pruba',
'prubă',
'prube',
'pruda',
'prudă',
'prude',
'pruei',
'pruha',
'pruhă',
'pruhe',
'pruja',
'prujă',
'pruji',
'pruna',
'prună',
'prunc',
'prund',
'prune',
'pruni',
'pruzi',
'psalm',
'psalt',
'psoas',
'ptoza',
'ptoză',
'ptoze',
'ptuși',
'puber',
'pubis',
'pubul',
'puchi',
'pucul',
'pudel',
'pudic',
'pudla',
'pudlă',
'pudli',
'pudra',
'pudră',
'pudre',
'pudul',
'pufăi',
'pufăr',
'pufii',
'pufna',
'pufnă',
'pufne',
'pufni',
'pufos',
'pufui',
'puful',
'puhav',
'puhei',
'puhoi',
'puhui',
'puian',
'puiat',
'puiau',
'puica',
'puică',
'puici',
'puiet',
'puind',
'puios',
'puiră',
'puire',
'puiri',
'puise',
'puita',
'puită',
'puite',
'puiți',
'puiuc',
'puiul',
'puiuț',
'pulia',
'pulie',
'pulii',
'pulpa',
'pulpă',
'pulpe',
'pulsa',
'pulsă',
'pulul',
'pumei',
'pumni',
'punci',
'punct',
'punea',
'punem',
'punga',
'pungă',
'pungi',
'punic',
'punii',
'punta',
'puntă',
'punte',
'punți',
'punul',
'punzi',
'pupai',
'pupam',
'pupat',
'pupau',
'pupăi',
'pupăm',
'pupei',
'pupic',
'pupii',
'pupil',
'pupoi',
'pupui',
'pupul',
'purec',
'purei',
'purga',
'purgă',
'puric',
'purii',
'purim',
'purja',
'purjă',
'purje',
'puroi',
'purta',
'purtă',
'purul',
'pusei',
'puseu',
'pusta',
'pustă',
'puste',
'pusul',
'pușca',
'pușcă',
'pușii',
'puște',
'puști',
'putea',
'putem',
'putui',
'putut',
'puțar',
'puțea',
'puții',
'puțim',
'puțin',
'puțit',
'puțul',
'puvoi',
'quale',
'quarc',
'quazi',
'quick',
'quipu',
'rabat',
'rabdă',
'rabde',
'rabia',
'rabic',
'rabie',
'rabii',
'rabin',
'rabiț',
'rabla',
'rablă',
'rable',
'rabzi',
'racem',
'racii',
'racla',
'raclă',
'racle',
'racul',
'radar',
'radei',
'radem',
'radia',
'radie',
'radio',
'radiu',
'radom',
'radon',
'radou',
'radul',
'rafia',
'rafie',
'rafii',
'raful',
'ragea',
'ragem',
'ragil',
'rahat',
'rahis',
'raion',
'raita',
'raită',
'raite',
'raiul',
'rajah',
'ralia',
'ralid',
'ralie',
'raliu',
'ralul',
'ramai',
'ramam',
'ramat',
'ramau',
'ramăm',
'ramca',
'ramcă',
'ramce',
'ramei',
'ramer',
'ramez',
'ramia',
'ramie',
'ramii',
'rampa',
'rampă',
'rampe',
'ramul',
'ranch',
'randa',
'randă',
'rande',
'ranga',
'rangă',
'rapăn',
'rapel',
'rapid',
'rapul',
'rarei',
'rarii',
'rarul',
'rasat',
'rasei',
'rasol',
'rasul',
'rașca',
'rașcă',
'rașel',
'rașii',
'rașpa',
'rașpă',
'rașpe',
'ratai',
'ratam',
'ratat',
'ratau',
'ratăm',
'ratei',
'rateș',
'rateu',
'ratez',
'ratoș',
'rațca',
'rațcă',
'rațce',
'raței',
'rația',
'rație',
'rații',
'ravac',
'razăm',
'razei',
'razia',
'razie',
'razii',
'razim',
'razna',
'raznă',
'razne',
'razul',
'răbda',
'răbdă',
'răboj',
'răbui',
'răbuș',
'răcan',
'răcar',
'răcea',
'răcii',
'răcim',
'răcit',
'răcni',
'rădan',
'rădea',
'rădic',
'rădiu',
'răfui',
'răgaz',
'răgea',
'răget',
'răgii',
'răgit',
'răgoz',
'rămas',
'rămâi',
'rămân',
'rănea',
'răngi',
'rănii',
'rănim',
'rănit',
'răpăi',
'răpea',
'răpii',
'răpim',
'răpit',
'răpui',
'răpun',
'răpus',
'rărea',
'rării',
'rărim',
'răriș',
'rărit',
'răsad',
'răsar',
'răsei',
'răsti',
'răsuc',
'răszi',
'rășci',
'rătez',
'rățoi',
'răvar',
'răvaș',
'răzat',
'răzau',
'răzbi',
'răzda',
'răzdă',
'răzeș',
'răzni',
'răzor',
'răzui',
'răzuș',
'râbar',
'râcâi',
'râcii',
'râdea',
'râdem',
'râgâi',
'râiei',
'râios',
'râlei',
'râmat',
'râmau',
'râmei',
'râmni',
'rânca',
'râncă',
'rânci',
'rânea',
'rânei',
'rânii',
'rânim',
'rânit',
'rânji',
'rânsa',
'rânsă',
'rânse',
'rânza',
'rânză',
'rânze',
'râpei',
'râpii',
'râpos',
'râsei',
'râset',
'râsul',
'râșii',
'râșni',
'râtan',
'râtul',
'râței',
'râura',
'râură',
'râuri',
'râvna',
'râvnă',
'râvne',
'râvni',
'râzna',
'râznă',
'râzne',
'readă',
'readu',
'reala',
'reală',
'reale',
'reali',
'reaua',
'rebab',
'rebat',
'rebec',
'rebel',
'rebus',
'rebut',
'recad',
'recăl',
'recea',
'recif',
'recii',
'recit',
'recoc',
'recte',
'recto',
'recul',
'recuz',
'redai',
'redam',
'redan',
'redat',
'redau',
'redăm',
'redea',
'redia',
'redie',
'redig',
'redii',
'rediș',
'rediu',
'redox',
'reduc',
'redus',
'refac',
'refec',
'refer',
'refug',
'reful',
'refuz',
'regal',
'regat',
'regea',
'regia',
'regie',
'regii',
'regim',
'regla',
'reglă',
'reiat',
'reiau',
'reiei',
'reies',
'rejet',
'rejoc',
'relaș',
'relee',
'releg',
'relei',
'releu',
'relev',
'relon',
'relua',
'reluă',
'remii',
'remis',
'remit',
'remul',
'remuu',
'renal',
'renan',
'renci',
'reneg',
'renet',
'renia',
'renie',
'renii',
'reniș',
'reniu',
'renta',
'rentă',
'rente',
'renul',
'repar',
'reped',
'reper',
'repet',
'repeț',
'repez',
'repui',
'repun',
'repus',
'resac',
'reșca',
'reșcă',
'reșce',
'reșou',
'retez',
'retor',
'retro',
'retur',
'retuș',
'rețea',
'reție',
'reții',
'rețin',
'rețiu',
'reuma',
'reumă',
'reume',
'reuni',
'reuși',
'revăd',
'revăz',
'rever',
'revie',
'revii',
'revin',
'reviu',
'revoc',
'rezec',
'rezem',
'rezil',
'rezol',
'rezon',
'rezum',
'riali',
'ricin',
'ricșa',
'ricșă',
'ricșe',
'ridai',
'ridam',
'ridat',
'ridau',
'ridăm',
'ridez',
'ridic',
'ridul',
'rifii',
'riflu',
'riful',
'rigăi',
'right',
'rigid',
'rigii',
'rigla',
'riglă',
'rigle',
'rijei',
'rilei',
'rilul',
'rimai',
'rimam',
'rimat',
'rimau',
'rimăm',
'rimei',
'rimel',
'rimez',
'rinei',
'ripai',
'ripam',
'ripat',
'ripau',
'ripăm',
'ripez',
'risca',
'riscă',
'rișca',
'rișcă',
'riște',
'riști',
'ritma',
'ritmă',
'ritor',
'ritos',
'ritul',
'rițin',
'rițui',
'rițul',
'rival',
'rizat',
'rizic',
'rizil',
'rizom',
'rizul',
'roaba',
'roabă',
'roabe',
'roada',
'roadă',
'roade',
'roagă',
'roage',
'roasa',
'roasă',
'roase',
'roast',
'roata',
'roată',
'roate',
'roază',
'robea',
'robei',
'robia',
'robie',
'robii',
'robim',
'robit',
'robot',
'robul',
'roche',
'rochi',
'rocii',
'rocul',
'rodai',
'rodaj',
'rodam',
'rodan',
'rodat',
'rodau',
'rodăm',
'rodea',
'rodeo',
'rodez',
'rodia',
'rodie',
'rodii',
'rodim',
'rodin',
'rodit',
'rodiu',
'rodor',
'rodos',
'rodul',
'rofii',
'rogoz',
'roiai',
'roiam',
'roiau',
'roiba',
'roibă',
'roibe',
'roibi',
'roind',
'roiră',
'roire',
'roiri',
'roise',
'roiși',
'roita',
'roită',
'roite',
'roiți',
'roiul',
'rolan',
'rolat',
'rolei',
'rolul',
'roman',
'român',
'romei',
'romeu',
'romii',
'romul',
'ronda',
'rondă',
'ronde',
'rondo',
'ronja',
'ronjă',
'ropăi',
'ropot',
'rosei',
'rosti',
'rosul',
'roșca',
'roșcă',
'roșea',
'roșei',
'roșia',
'roșie',
'roșii',
'roșim',
'roșit',
'roște',
'roșul',
'rotai',
'rotam',
'rotar',
'rotaș',
'rotat',
'rotau',
'rotăm',
'rotea',
'rotez',
'rotii',
'rotim',
'rotin',
'rotiș',
'rotit',
'rotor',
'roții',
'rouăi',
'rouei',
'roura',
'roură',
'rouța',
'rouță',
'rouțe',
'rozei',
'rozia',
'rozie',
'rozii',
'roziu',
'rozul',
'rroma',
'rromă',
'rrome',
'rromi',
'ruaje',
'rubai',
'rubia',
'rubin',
'rubla',
'rublă',
'ruble',
'rudar',
'rudea',
'rudei',
'rudii',
'rudim',
'rudit',
'rufei',
'rufet',
'rufos',
'ruful',
'rugai',
'rugam',
'rugat',
'rugau',
'rugăm',
'rugbi',
'rugby',
'rugea',
'ruget',
'rugii',
'rugim',
'rugit',
'rugos',
'rugul',
'ruina',
'ruină',
'ruine',
'ruini',
'rujai',
'rujam',
'rujan',
'rujar',
'rujat',
'rujau',
'rujăm',
'rujei',
'rujet',
'rujez',
'rujii',
'rujul',
'rulai',
'rulaj',
'rulam',
'rulat',
'rulau',
'rulăm',
'rulez',
'ruliu',
'rulou',
'rumân',
'rumba',
'rumbă',
'rumbe',
'rumeg',
'rumen',
'rummy',
'rumpă',
'rumpe',
'rumpi',
'rumpt',
'rumse',
'rumta',
'rumtă',
'rumte',
'rumți',
'rumul',
'runda',
'rundă',
'runde',
'runei',
'runic',
'rupea',
'rupem',
'rupia',
'rupie',
'rupii',
'rupse',
'rupta',
'ruptă',
'rupte',
'rupți',
'rural',
'rusca',
'ruscă',
'rusce',
'rusei',
'russe',
'rusul',
'rușii',
'ruște',
'rutei',
'rutil',
'rutul',
'sabaș',
'sabat',
'sabia',
'sabie',
'sabin',
'sabir',
'sabla',
'sablă',
'sabot',
'sabur',
'sabuș',
'sacâz',
'sacii',
'sacos',
'sacou',
'sacra',
'sacră',
'sacre',
'sacri',
'sacru',
'sacul',
'sadea',
'sadei',
'sadic',
'sadul',
'safeu',
'safic',
'safir',
'sagna',
'sagnă',
'sagne',
'sagum',
'sahan',
'sahel',
'saigi',
'salam',
'salar',
'salba',
'salbă',
'salbe',
'salca',
'salcă',
'salce',
'salep',
'saleu',
'salic',
'salin',
'salip',
'salol',
'salon',
'salsa',
'salse',
'saltă',
'salte',
'salța',
'salță',
'salțe',
'salți',
'salul',
'salup',
'salut',
'salva',
'salvă',
'salve',
'samar',
'samba',
'sambă',
'sambe',
'sambo',
'samca',
'samcă',
'samce',
'samei',
'sameș',
'samur',
'sanda',
'sania',
'sanie',
'sapei',
'sapia',
'sarai',
'sarda',
'sardă',
'sarde',
'sarea',
'saric',
'sarma',
'saros',
'sarzi',
'sasău',
'sasca',
'sască',
'sasce',
'sasul',
'sașeu',
'sașia',
'sașie',
'sașii',
'sașiu',
'satan',
'satâr',
'saten',
'satin',
'satir',
'satul',
'satur',
'sațiu',
'sațul',
'saula',
'saulă',
'saule',
'sauna',
'saună',
'saune',
'savai',
'savar',
'savor',
'savur',
'saxon',
'săbăi',
'săbău',
'săbia',
'săbie',
'săbii',
'săbui',
'săcoi',
'săcos',
'săcui',
'sădea',
'sădii',
'sădim',
'sădit',
'săgni',
'sălaș',
'sălci',
'sălia',
'sălie',
'sălii',
'sălta',
'săltă',
'sănia',
'sănie',
'sănii',
'săpai',
'săpam',
'săpat',
'săpau',
'săpăm',
'săpoi',
'săpun',
'sărac',
'sărad',
'sărai',
'săram',
'sărar',
'sărat',
'sărau',
'sărăm',
'sărea',
'sărez',
'sării',
'sărim',
'sărin',
'sărit',
'sărut',
'săști',
'sătuc',
'sătui',
'sătul',
'sătuț',
'sâcâi',
'sâneț',
'sânge',
'sânii',
'sânta',
'sântă',
'sânte',
'sânți',
'sânul',
'sârba',
'sârbă',
'sârbe',
'sârbi',
'sârma',
'sârmă',
'sârme',
'sâsâi',
'scadă',
'scade',
'scafa',
'scafă',
'scafe',
'scaii',
'scala',
'scală',
'scald',
'scale',
'scalp',
'scama',
'scamă',
'scame',
'scana',
'scană',
'scapă',
'scape',
'scapi',
'scara',
'scară',
'scarp',
'scaun',
'scază',
'scazi',
'scăpa',
'scăpă',
'scări',
'scăzu',
'scârm',
'scârț',
'scena',
'scenă',
'scene',
'schia',
'schie',
'schif',
'schip',
'schit',
'scita',
'scită',
'scite',
'sciți',
'sclai',
'sclav',
'scobi',
'scoci',
'scoli',
'scont',
'scopi',
'scopt',
'scoși',
'scota',
'scotă',
'scote',
'scoți',
'screm',
'scria',
'scrib',
'scrie',
'scrii',
'scrim',
'scrin',
'scris',
'scriu',
'scrob',
'scrot',
'scrum',
'scuar',
'scufa',
'scufă',
'scufe',
'scuip',
'scula',
'sculă',
'scule',
'scump',
'scuna',
'scună',
'scund',
'scune',
'scurg',
'scurm',
'scurs',
'scurt',
'scuti',
'scuți',
'scuza',
'scuză',
'scuze',
'scuzi',
'seaca',
'seacă',
'seama',
'seamă',
'seara',
'seară',
'sebum',
'secai',
'secam',
'secat',
'secau',
'secăm',
'secer',
'secii',
'secol',
'secta',
'sectă',
'secte',
'secui',
'secul',
'sedai',
'sedam',
'sedat',
'sedau',
'sedăm',
'sedez',
'sedii',
'sediu',
'seduc',
'sedus',
'seful',
'segno',
'seiga',
'seigă',
'seige',
'seima',
'seimă',
'seime',
'seina',
'seină',
'seine',
'seini',
'seism',
'seișa',
'seișă',
'seișe',
'seizi',
'sejur',
'selba',
'selbă',
'selbe',
'selva',
'selvă',
'selve',
'semăn',
'semem',
'semen',
'semeț',
'semic',
'semii',
'semit',
'semna',
'semnă',
'semne',
'semui',
'semul',
'senar',
'senat',
'senei',
'senet',
'senii',
'senil',
'senin',
'senul',
'separ',
'sepia',
'sepie',
'sepii',
'serai',
'seral',
'serat',
'serba',
'serbă',
'serei',
'seria',
'seric',
'serie',
'serii',
'seros',
'serul',
'serva',
'servă',
'serve',
'servi',
'servo',
'sesam',
'sesia',
'sesie',
'sesii',
'sesil',
'setai',
'setam',
'setat',
'setau',
'setăm',
'setca',
'setcă',
'setci',
'setea',
'setei',
'seter',
'setez',
'setos',
'setul',
'seuri',
'sevai',
'sevas',
'sevei',
'sever',
'sexta',
'sextă',
'sexte',
'sexul',
'sezon',
'sfada',
'sfadă',
'sfanț',
'sfara',
'sfară',
'sfare',
'sfădi',
'sfărm',
'sfânt',
'sfârc',
'sfera',
'sferă',
'sfere',
'sfert',
'sfeti',
'sfezi',
'sfiai',
'sfiam',
'sfiau',
'sfida',
'sfidă',
'sfieț',
'sfiii',
'sfiim',
'sfiit',
'sfinx',
'sfios',
'sfita',
'sfită',
'sfite',
'sfori',
'siaje',
'sibir',
'sicar',
'sicii',
'sidef',
'sieși',
'sifon',
'sigei',
'sigil',
'sigla',
'siglă',
'sigle',
'sigma',
'sigur',
'sihla',
'sihlă',
'sihle',
'silan',
'silca',
'silcă',
'silce',
'silea',
'silei',
'silen',
'silex',
'silfa',
'silfă',
'silfe',
'silfi',
'silha',
'silhă',
'silhe',
'silii',
'silim',
'silit',
'silon',
'siloz',
'silui',
'silul',
'silur',
'silva',
'silvă',
'silve',
'silvo',
'simei',
'simen',
'simit',
'simtă',
'simte',
'simță',
'simți',
'simun',
'sinea',
'sinet',
'sineț',
'sinia',
'sinie',
'sinii',
'sinod',
'sinta',
'sintă',
'sinte',
'sinul',
'sinus',
'sipet',
'sirec',
'sirep',
'siric',
'sirop',
'sisal',
'sista',
'sistă',
'sitar',
'sitav',
'sitei',
'situa',
'situă',
'situl',
'sivei',
'sivii',
'sivul',
'sixta',
'sixtă',
'sixte',
'skate',
'skeet',
'slaba',
'slabă',
'slabe',
'slabi',
'slana',
'slană',
'slane',
'slang',
'slash',
'slava',
'slavă',
'slave',
'slavi',
'slăbi',
'slăvi',
'sleia',
'sleii',
'sleim',
'sleit',
'slops',
'slova',
'slovă',
'slove',
'slovo',
'sluga',
'slugă',
'slugi',
'sluis',
'sluji',
'sluta',
'slută',
'slute',
'sluti',
'sluți',
'smalt',
'smalț',
'smash',
'smâci',
'smârc',
'smârd',
'smead',
'smeci',
'smede',
'smeri',
'smezi',
'smida',
'smidă',
'smide',
'smint',
'smoli',
'smomi',
'smuci',
'smuga',
'smugă',
'smugi',
'smulg',
'smuls',
'smult',
'snack',
'snaga',
'snagă',
'snăgi',
'snobi',
'snopi',
'soaie',
'soare',
'soața',
'soață',
'soațe',
'sobar',
'sobei',
'sobol',
'sobor',
'sobra',
'sobră',
'sobre',
'sobri',
'sobru',
'socii',
'soclu',
'socot',
'socri',
'socru',
'socul',
'sodar',
'sodei',
'sodic',
'sodiu',
'sodom',
'sofei',
'sofra',
'soiai',
'soiam',
'soiau',
'soiei',
'soind',
'soios',
'soiră',
'soire',
'soiri',
'soise',
'soiși',
'soita',
'soită',
'soite',
'soiți',
'soiul',
'solar',
'solba',
'solbă',
'solbe',
'solda',
'soldă',
'solde',
'solea',
'solei',
'solia',
'solid',
'solie',
'solii',
'solim',
'solit',
'solul',
'solvă',
'solve',
'solvi',
'solzi',
'somai',
'somam',
'somat',
'somau',
'somăm',
'somei',
'somez',
'somna',
'somnă',
'somni',
'somon',
'sonai',
'sonam',
'sonar',
'sonat',
'sonau',
'sonăm',
'sonda',
'sondă',
'sonde',
'sonet',
'sonez',
'sonic',
'sonii',
'sonor',
'sonul',
'sopon',
'sopor',
'sorbi',
'sorea',
'sorei',
'sorii',
'sorim',
'sorit',
'soroc',
'soros',
'sorta',
'sortă',
'sorti',
'sorți',
'sorul',
'sosea',
'sosia',
'sosie',
'sosii',
'sosim',
'sosit',
'sosma',
'sosul',
'soția',
'soție',
'soții',
'soțul',
'sound',
'sovon',
'spada',
'spadă',
'spade',
'spaga',
'spagă',
'spală',
'sparg',
'spart',
'spasm',
'spata',
'spată',
'spate',
'spăgi',
'spăla',
'spălă',
'spăsi',
'spăși',
'spâna',
'spână',
'spâne',
'spâni',
'spânț',
'spânz',
'spârc',
'spelb',
'spele',
'speli',
'spera',
'speră',
'spere',
'speri',
'spese',
'spete',
'speti',
'speța',
'speță',
'spețe',
'speți',
'speze',
'spice',
'spina',
'spină',
'spine',
'spini',
'spion',
'spira',
'spiră',
'spire',
'spirt',
'spița',
'spiță',
'spițe',
'splai',
'splau',
'splin',
'spoia',
'spoii',
'spoim',
'spoit',
'spori',
'sport',
'spray',
'spuie',
'spuma',
'spumă',
'spume',
'spună',
'spune',
'spurc',
'spusa',
'spusă',
'spuse',
'spuși',
'sputa',
'spută',
'spute',
'spuza',
'spuză',
'spuze',
'spuzi',
'staff',
'stana',
'stană',
'stand',
'stane',
'stani',
'stare',
'start',
'state',
'stați',
'staul',
'stava',
'stavă',
'stave',
'staza',
'stază',
'staze',
'stări',
'stătu',
'stăvi',
'stâlp',
'stâna',
'stână',
'stând',
'stâne',
'stâng',
'stârc',
'stârv',
'steag',
'stean',
'steie',
'stela',
'stelă',
'stele',
'steli',
'stema',
'stemă',
'steme',
'steni',
'stepa',
'stepă',
'stepe',
'steri',
'stern',
'sterp',
'stete',
'steve',
'stila',
'stilă',
'stilb',
'stile',
'stima',
'stimă',
'stime',
'sting',
'stins',
'stiva',
'stivă',
'stive',
'stoca',
'stocă',
'stoci',
'stofa',
'stofă',
'stofe',
'stoic',
'stola',
'stolă',
'stole',
'stopa',
'stopă',
'storc',
'stors',
'story',
'strai',
'stras',
'strat',
'strei',
'stres',
'stria',
'stric',
'strie',
'strig',
'strii',
'striu',
'stroh',
'stroi',
'strop',
'strug',
'strup',
'struț',
'stufa',
'stufă',
'stufe',
'stunt',
'stupa',
'stupă',
'stupe',
'stupi',
'sturz',
'style',
'suava',
'suavă',
'suave',
'suavi',
'suber',
'subit',
'subra',
'sucea',
'sucii',
'sucim',
'sucit',
'sucna',
'sucnă',
'sucne',
'sucul',
'sudac',
'sudai',
'sudaj',
'sudam',
'sudat',
'sudau',
'sudăm',
'sudea',
'sudez',
'sudic',
'sudii',
'sudim',
'sudit',
'sudor',
'sudui',
'sudul',
'sufăr',
'sufit',
'sufix',
'sufla',
'suflă',
'sufle',
'sufli',
'suflu',
'sufoc',
'sugar',
'sugea',
'sugei',
'sugel',
'sugem',
'suguș',
'suhat',
'suiai',
'suiam',
'suiau',
'suina',
'suină',
'suind',
'suine',
'suini',
'suiră',
'suire',
'suiri',
'suise',
'suiși',
'suita',
'suită',
'suite',
'suiți',
'sujet',
'sulac',
'sulei',
'sulky',
'sulta',
'sultă',
'sulte',
'sulul',
'sumac',
'sumai',
'sumam',
'suman',
'sumar',
'sumat',
'sumau',
'sumăm',
'sumec',
'sumei',
'sumes',
'sumet',
'sumeț',
'sumez',
'summa',
'sumuț',
'sunai',
'sunam',
'sunat',
'sunau',
'sunăm',
'sunet',
'sunna',
'sunne',
'supai',
'supam',
'supat',
'supau',
'supăm',
'supăr',
'supei',
'super',
'supeu',
'supez',
'supie',
'supin',
'supla',
'suplă',
'suple',
'supli',
'suplu',
'supra',
'supse',
'supta',
'suptă',
'supte',
'supți',
'supui',
'supun',
'supus',
'surâd',
'surâs',
'surâz',
'surda',
'surdă',
'surde',
'surea',
'surei',
'suret',
'suria',
'surie',
'surii',
'surim',
'surit',
'suriu',
'surla',
'surlă',
'surle',
'surpa',
'surpă',
'surpe',
'surpi',
'sursa',
'sursă',
'surse',
'surul',
'surzi',
'susai',
'susam',
'susan',
'susul',
'susur',
'sușei',
'sutar',
'sutaș',
'sutea',
'sutei',
'suvac',
'suvai',
'svada',
'svadă',
'svezi',
'sving',
'swazi',
'swing',
'șabac',
'șabăr',
'șacal',
'șafar',
'șafăr',
'șahii',
'șahul',
'șaiba',
'șaibă',
'șaibe',
'șaica',
'șaică',
'șaiul',
'șalăi',
'șalău',
'șaliu',
'șalul',
'șaman',
'șamot',
'șamul',
'șanal',
'șansa',
'șansă',
'șanse',
'șanul',
'șapca',
'șapcă',
'șapei',
'șapte',
'șaran',
'șarba',
'șarbă',
'șarga',
'șargă',
'șarge',
'șargi',
'șarja',
'șarjă',
'șarje',
'șarla',
'șarlă',
'șarle',
'șarpe',
'șarul',
'șasea',
'șasiu',
'șasla',
'șașia',
'șașie',
'șașii',
'șașiu',
'șaten',
'șatra',
'șatră',
'șatre',
'șădea',
'șădem',
'șăgii',
'șăgui',
'șăici',
'șăluț',
'șăuaș',
'șăzui',
'șăzut',
'șâșâi',
'școli',
'șeasa',
'șeasă',
'șeaua',
'ședea',
'ședem',
'șefei',
'șefia',
'șefie',
'șefii',
'șeful',
'șeiac',
'șeici',
'șeihi',
'șeile',
'șelac',
'șelar',
'șelei',
'șenal',
'șepci',
'șepte',
'șerbe',
'șerbi',
'șerif',
'șerpe',
'șerpi',
'șerui',
'șesar',
'șesea',
'șesei',
'șesul',
'șeșii',
'șetre',
'șever',
'șevro',
'șezui',
'șezut',
'șfanț',
'șfara',
'șfară',
'șfare',
'șfarț',
'șfeii',
'șferi',
'șfert',
'șfori',
'șiace',
'șiboi',
'șicli',
'șicui',
'șicul',
'șieși',
'șifon',
'șiful',
'șiile',
'șiism',
'șiita',
'șiită',
'șiite',
'șiiți',
'șinar',
'șinei',
'șinor',
'șinui',
'șipca',
'șipcă',
'șipci',
'șipii',
'șipot',
'șipul',
'șirag',
'șirei',
'șiret',
'șiroi',
'șirui',
'șirul',
'șiruț',
'șișăi',
'șișca',
'șișcă',
'șiște',
'șiști',
'șișul',
'șițar',
'șiței',
'șițui',
'șivoi',
'șlapi',
'șleah',
'șleau',
'șleii',
'șlice',
'șlire',
'șliri',
'șmale',
'șnaps',
'șoada',
'șoadă',
'șoade',
'șocai',
'șocam',
'șocat',
'șocau',
'șocăm',
'șocul',
'șodou',
'șodul',
'șofai',
'șofaj',
'șofam',
'șofat',
'șofau',
'șofăm',
'șofer',
'șofez',
'șogor',
'șohan',
'șoimi',
'șoldi',
'șolzi',
'șomai',
'șomaj',
'șomam',
'șomat',
'șomau',
'șomăm',
'șomâc',
'șomer',
'șomez',
'șonți',
'șopăi',
'șopei',
'șopot',
'șopru',
'șopti',
'șopul',
'șorec',
'șoric',
'șosea',
'șoșet',
'șoșii',
'șoșoi',
'șoșon',
'șoșul',
'șotia',
'șotie',
'șotii',
'șotul',
'șovan',
'șovar',
'șovăi',
'șovin',
'șozii',
'șpaga',
'șpagă',
'șpais',
'șpalt',
'șpani',
'șpăgi',
'șperț',
'șpiți',
'șplit',
'șpori',
'șpriț',
'ștabi',
'ștaif',
'ștair',
'ștand',
'ștate',
'șteaf',
'șteap',
'ștepi',
'șterg',
'șteri',
'șters',
'șterț',
'șteze',
'știai',
'știam',
'știau',
'știft',
'știma',
'știmă',
'știme',
'știmi',
'știob',
'știra',
'știră',
'știrb',
'știre',
'știri',
'știți',
'știui',
'știut',
'ștofa',
'ștofă',
'ștofe',
'ștraf',
'ștras',
'șturț',
'șubei',
'șuber',
'șucar',
'șucăr',
'șucul',
'șueta',
'șuetă',
'șuete',
'șufan',
'șugar',
'șugui',
'șuiai',
'șuiam',
'șuiat',
'șuiau',
'șuiei',
'șuiem',
'șuier',
'șuiet',
'șuieț',
'șuiez',
'șuind',
'șuița',
'șuiță',
'șuițe',
'șuiul',
'șular',
'șuler',
'șulfa',
'șulfă',
'șulfe',
'șumar',
'șumăn',
'șumen',
'șunca',
'șuncă',
'șunci',
'șurei',
'șurii',
'șurub',
'șurup',
'șusta',
'șustă',
'șuste',
'șușui',
'șutai',
'șutam',
'șutat',
'șutau',
'șutăm',
'șutea',
'șutei',
'șuter',
'șutez',
'șutii',
'șutim',
'șutit',
'șutor',
'șutul',
'șuții',
'șuțul',
'șuvar',
'șuvoi',
'șvaba',
'șvabă',
'șvabe',
'șvabi',
'șvarț',
'șveda',
'șvedă',
'șvede',
'șvezi',
'tabac',
'taban',
'tabar',
'tabăr',
'tabel',
'tabes',
'tabia',
'tabie',
'tabii',
'tabla',
'tablă',
'table',
'tabun',
'tacâm',
'tacit',
'tacla',
'tacta',
'tactă',
'tacul',
'tafta',
'taftă',
'tafte',
'tagăi',
'tagma',
'tagmă',
'tagme',
'tahân',
'taica',
'taică',
'taier',
'taiga',
'taina',
'taină',
'taine',
'taior',
'talaj',
'talan',
'talar',
'talaș',
'talaz',
'talâm',
'talei',
'taler',
'talia',
'talie',
'talii',
'talim',
'taliu',
'talon',
'talpa',
'talpă',
'talul',
'talus',
'taluz',
'taman',
'tamar',
'tamâș',
'tamja',
'tamjă',
'tamje',
'tanaj',
'tanat',
'tanăi',
'tanăt',
'tanău',
'tanea',
'tanga',
'tangă',
'tanic',
'tanin',
'tanti',
'taola',
'taolă',
'taole',
'tapai',
'tapaj',
'tapam',
'tapat',
'tapau',
'tapăm',
'tapei',
'tapet',
'tapez',
'tapir',
'tarac',
'taraf',
'tarar',
'taraș',
'tarat',
'tarea',
'tarei',
'targa',
'targă',
'tarif',
'tarii',
'tarla',
'taroc',
'tarod',
'tarot',
'tarta',
'tartă',
'tarte',
'tarul',
'tasai',
'tasam',
'tasat',
'tasau',
'tasăm',
'taseu',
'tasez',
'tasma',
'tason',
'tasta',
'tastă',
'taste',
'tasul',
'tașca',
'tașcă',
'tatăl',
'tatei',
'tatii',
'tatua',
'tatuă',
'tații',
'taula',
'taulă',
'taule',
'tauri',
'tavan',
'taxai',
'taxam',
'taxat',
'taxau',
'taxăm',
'taxei',
'taxez',
'taxid',
'taxim',
'tăcăi',
'tăcea',
'tăcem',
'tăcui',
'tăcut',
'tăgii',
'tăiai',
'tăiam',
'tăiat',
'tăiau',
'tăiem',
'tăier',
'tăind',
'tăios',
'tălpi',
'tămâi',
'tăpși',
'tărâm',
'tărca',
'tărcă',
'tărgi',
'tăria',
'tărie',
'tării',
'tăști',
'tătar',
'tătân',
'tătic',
'tătuc',
'tătuț',
'tăuia',
'tăuii',
'tăuim',
'tăuit',
'tăune',
'tăuni',
'tăuri',
'tăuși',
'tăvii',
'tâcâi',
'tâlvi',
'tâmpa',
'tâmpă',
'tâmpe',
'tâmpi',
'tânăr',
'tânga',
'tângă',
'tângi',
'tânji',
'tânta',
'tântă',
'tânte',
'tânți',
'târai',
'târam',
'târau',
'târâi',
'târâm',
'târâș',
'târât',
'târfa',
'târfă',
'târfe',
'târla',
'târlă',
'târle',
'târli',
'târna',
'târnă',
'târne',
'târsa',
'târsă',
'târse',
'târși',
'tâțoi',
'teaca',
'teacă',
'teama',
'teamă',
'teanc',
'teapa',
'teapă',
'teara',
'teară',
'teasc',
'tecii',
'tecul',
'tefal',
'tehno',
'tehui',
'teica',
'teică',
'teici',
'teier',
'teina',
'teină',
'teine',
'teios',
'teism',
'teist',
'teiul',
'telal',
'telex',
'telul',
'telur',
'temea',
'temei',
'temem',
'temii',
'tempo',
'temui',
'temut',
'tenda',
'tendă',
'tende',
'tenia',
'tenie',
'tenii',
'tenis',
'tenor',
'tenta',
'tentă',
'tente',
'tenul',
'tepei',
'teras',
'teraz',
'terci',
'teren',
'terfe',
'terii',
'terme',
'termo',
'terna',
'ternă',
'terne',
'terni',
'terța',
'terță',
'terțe',
'terți',
'tesac',
'tesla',
'teslă',
'tesle',
'testa',
'testă',
'teste',
'teșea',
'teșii',
'teșim',
'teșit',
'teșos',
'tetea',
'tetei',
'tetra',
'tetre',
'teuca',
'teucă',
'teuci',
'teuga',
'teugă',
'teuge',
'teuri',
'texan',
'texte',
'texul',
'tezei',
'tiara',
'tiară',
'tiare',
'tibet',
'tibia',
'tibii',
'ticăi',
'ticna',
'ticnă',
'ticne',
'ticni',
'ticsi',
'ticul',
'tifan',
'tific',
'tifla',
'tiflă',
'tifle',
'tifon',
'tifos',
'tigăi',
'tigra',
'tigră',
'tigre',
'tigri',
'tigru',
'tigva',
'tigvă',
'tigve',
'tihai',
'tihna',
'tihnă',
'tihne',
'tihni',
'tihui',
'tijei',
'tilda',
'tildă',
'tilde',
'timar',
'timfi',
'timia',
'timid',
'timie',
'timii',
'timin',
'timol',
'timpi',
'timus',
'tinăr',
'tinca',
'tincă',
'tinci',
'tinda',
'tindă',
'tinde',
'tinei',
'tinos',
'tinsa',
'tinsă',
'tinse',
'tinși',
'tinză',
'tinzi',
'tioli',
'tipar',
'tipăi',
'tipea',
'tipei',
'tipia',
'tipic',
'tipie',
'tipii',
'tipim',
'tipit',
'tiplu',
'tipos',
'tipul',
'tiraj',
'tiran',
'tirul',
'tisei',
'tisul',
'tișii',
'tiști',
'titan',
'titlu',
'titra',
'titră',
'titre',
'titru',
'tiuia',
'tiuii',
'tiuim',
'tiuit',
'tivai',
'tivea',
'tivga',
'tivgă',
'tivgi',
'tivic',
'tivii',
'tivim',
'tivit',
'tivul',
'tizei',
'tizic',
'tizii',
'tizul',
'tmeza',
'tmeză',
'tmeze',
'toaca',
'toacă',
'toace',
'toaia',
'toaie',
'toana',
'toană',
'toane',
'toast',
'toată',
'toate',
'tobaș',
'tobei',
'tocai',
'tocam',
'tocat',
'tocau',
'tocăm',
'tocea',
'tocii',
'tocim',
'tocit',
'tocma',
'tocmă',
'tocme',
'tocmi',
'tocot',
'tocul',
'tofus',
'togii',
'toiag',
'toiai',
'toiam',
'toiau',
'toile',
'toind',
'toiră',
'toire',
'toiri',
'toise',
'toiși',
'toiți',
'toiul',
'tolba',
'tolbă',
'tolbe',
'tolei',
'tomna',
'tomnă',
'tomul',
'tonaj',
'tonal',
'tondo',
'tonei',
'toner',
'tonic',
'tonii',
'tonos',
'tonou',
'tonți',
'tonul',
'tonus',
'topaz',
'topea',
'topic',
'topii',
'topim',
'topit',
'topor',
'topos',
'topul',
'topuz',
'torba',
'torbă',
'torbe',
'torci',
'torid',
'torit',
'toriu',
'torni',
'toron',
'torri',
'torși',
'torta',
'tortă',
'torte',
'torța',
'torță',
'torțe',
'torți',
'torul',
'tosul',
'total',
'totem',
'totul',
'toții',
'toval',
'toxic',
'traca',
'tracă',
'trace',
'traci',
'tract',
'tradu',
'traga',
'tragă',
'trage',
'tragi',
'trama',
'tramă',
'trame',
'tranc',
'trans',
'trapa',
'trapă',
'trape',
'trasa',
'trasă',
'trase',
'trass',
'trași',
'trata',
'trată',
'trate',
'traul',
'trăda',
'trădă',
'trăgi',
'trăia',
'trăii',
'trăim',
'trăit',
'trând',
'treaz',
'trebi',
'trece',
'treci',
'trecu',
'treia',
'treji',
'trele',
'trema',
'tremă',
'treme',
'trena',
'trenă',
'trend',
'trene',
'tresa',
'tresă',
'trese',
'treti',
'treze',
'trezi',
'triac',
'triai',
'triaj',
'trial',
'triam',
'triat',
'triau',
'trico',
'triem',
'trier',
'triez',
'triod',
'trior',
'trist',
'trișa',
'trișă',
'trona',
'tronă',
'tronc',
'tropa',
'tropi',
'trosc',
'trota',
'trotă',
'truba',
'trubă',
'trube',
'truca',
'trucă',
'truci',
'truda',
'trudă',
'trude',
'trudi',
'trufa',
'trufă',
'trufe',
'trufi',
'trupa',
'trupă',
'trupe',
'trusa',
'trusă',
'truse',
'trust',
'tubai',
'tubaj',
'tubam',
'tubat',
'tubau',
'tubăm',
'tubei',
'tubez',
'tubul',
'tucan',
'tufan',
'tufar',
'tufei',
'tufiș',
'tufit',
'tufli',
'tufos',
'tuful',
'tugul',
'tuiai',
'tuiam',
'tuiau',
'tuiei',
'tuieș',
'tuile',
'tuind',
'tuior',
'tuiră',
'tuire',
'tuiri',
'tuise',
'tuiși',
'tuita',
'tuită',
'tuite',
'tuiți',
'tuiul',
'tulai',
'tulea',
'tulei',
'tuleu',
'tulii',
'tulim',
'tulit',
'tuliu',
'tulți',
'tulul',
'tumba',
'tumbă',
'tumbe',
'tumul',
'tunai',
'tunam',
'tunar',
'tunat',
'tunau',
'tunăm',
'tundă',
'tunde',
'tunel',
'tuner',
'tunet',
'tunsa',
'tunsă',
'tunse',
'tunși',
'tunul',
'tunză',
'tunzi',
'tupeu',
'turan',
'turba',
'turbă',
'turbe',
'turbi',
'turca',
'turcă',
'turce',
'turci',
'turei',
'turla',
'turlă',
'turle',
'turma',
'turmă',
'turme',
'turna',
'turnă',
'turta',
'turtă',
'turte',
'turti',
'turui',
'turul',
'tusea',
'tusei',
'tuset',
'tusor',
'tușai',
'tușam',
'tușat',
'tușau',
'tușăm',
'tușea',
'tușei',
'tușeu',
'tușez',
'tușii',
'tușim',
'tușit',
'tușul',
'tutea',
'tutei',
'tutoi',
'tutor',
'tutti',
'tutuc',
'tutui',
'tutun',
'tuția',
'tuție',
'tuții',
'tweed',
'twist',
'țagla',
'țaglă',
'țagle',
'țaica',
'țaică',
'țaici',
'țalii',
'țalul',
'țapap',
'țapii',
'țapin',
'țapoș',
'țapul',
'țarat',
'țarca',
'țarcă',
'țarii',
'țarna',
'țarnă',
'țarul',
'țaței',
'țăcăn',
'țăhni',
'țăpoi',
'țăpuc',
'țăran',
'țărci',
'țării',
'țărna',
'țărnă',
'țărne',
'țărni',
'țăruș',
'țâfei',
'țâfla',
'țâflă',
'țâfle',
'țâfna',
'țâfnă',
'țâfne',
'țâfni',
'țâful',
'țâhla',
'țâhlă',
'țâhle',
'țâlea',
'țânaș',
'țânca',
'țâncă',
'țânce',
'țânci',
'țârău',
'țârâi',
'țârei',
'țârfa',
'țârfă',
'țârfe',
'țârii',
'țârul',
'țâșni',
'țâști',
'țâțâi',
'țâței',
'țâțos',
'țâțul',
'țeapa',
'țeapă',
'țeara',
'țeară',
'țeasă',
'țeava',
'țeavă',
'țeche',
'țechi',
'țelea',
'țelii',
'țelim',
'țelit',
'țelul',
'țepei',
'țepii',
'țepos',
'țepul',
'țerii',
'țesal',
'țesăl',
'țesea',
'țesem',
'țeste',
'țesui',
'țesut',
'țevar',
'țevii',
'țevos',
'țevui',
'țicla',
'țiclă',
'țicle',
'țicni',
'țicui',
'țicur',
'țiful',
'țigan',
'țigăi',
'țigâi',
'țigla',
'țiglă',
'țigle',
'țiind',
'țilăi',
'țimir',
'ținci',
'ținea',
'ținem',
'ținta',
'țintă',
'ținte',
'ținti',
'ținui',
'ținut',
'țipai',
'țipam',
'țipar',
'țipat',
'țipau',
'țipăi',
'țipăm',
'țipăt',
'țipău',
'țipet',
'țipiș',
'țipla',
'țiplă',
'țiple',
'țipoi',
'țișni',
'țiței',
'țiuia',
'țiuie',
'țiuii',
'țiuim',
'țiuit',
'țiuri',
'țivil',
'țivli',
'țoaba',
'țoabă',
'țoabe',
'țoala',
'țoală',
'țoale',
'țoapa',
'țoapă',
'țoape',
'țobâc',
'țocăi',
'țocni',
'țocul',
'țoiul',
'țolet',
'țolii',
'țolul',
'țoluț',
'țopăi',
'țopâc',
'țopii',
'țopul',
'țoșca',
'țoșcă',
'țoști',
'țucai',
'țucal',
'țucam',
'țucat',
'țucau',
'țucăm',
'țugui',
'țugul',
'țuhal',
'țuhăi',
'țuica',
'țuică',
'țuici',
'țuluc',
'țupăi',
'țurca',
'țurcă',
'țurci',
'țuruc',
'țușca',
'țușcă',
'țuști',
'țuțui',
'țuțul',
'țuțur',
'ubace',
'ucidă',
'ucide',
'ucigă',
'ucisa',
'ucisă',
'ucise',
'uciși',
'uciză',
'ucizi',
'uclei',
'ucuri',
'udară',
'udare',
'udase',
'udași',
'udata',
'udată',
'udate',
'udați',
'udări',
'udând',
'udeai',
'udeam',
'udeau',
'udele',
'udeli',
'udesc',
'udind',
'udiră',
'udire',
'udiri',
'udise',
'udiși',
'udiți',
'udmei',
'uduri',
'uedul',
'ugere',
'ughii',
'ugnea',
'ugnii',
'ugnim',
'ugnit',
'uguia',
'uguie',
'uguit',
'uhale',
'uhaua',
'uiaga',
'uiagă',
'uiați',
'uibul',
'uicăi',
'uicii',
'uideo',
'uiegi',
'uiesc',
'uiete',
'uilor',
'uimea',
'uimei',
'uimii',
'uimim',
'uimit',
'uimul',
'uinei',
'uirăm',
'uirea',
'uirii',
'uisem',
'uitai',
'uitam',
'uitat',
'uitau',
'uităm',
'uitit',
'uituc',
'uiuiu',
'ujbei',
'ujuia',
'ujuie',
'ujuit',
'ulaje',
'ulani',
'ulcea',
'ulcer',
'ulcus',
'ulduc',
'uleia',
'uleie',
'uleii',
'ulema',
'uleul',
'ulieș',
'uliii',
'ulița',
'uliță',
'ulițe',
'uliți',
'uliul',
'uliuț',
'ulmai',
'ulmam',
'ulmat',
'ulmau',
'ulmăm',
'ulmii',
'ulmul',
'ulnar',
'ulnei',
'ultim',
'ultra',
'uluba',
'ulubă',
'ulube',
'uluca',
'ulucă',
'uluce',
'uluci',
'uluia',
'uluii',
'uluim',
'uluit',
'umana',
'umană',
'umane',
'umani',
'umbla',
'umblă',
'umble',
'umbli',
'umblu',
'umbon',
'umbra',
'umbră',
'umbre',
'umbri',
'umeda',
'umedă',
'umede',
'umere',
'umeri',
'umezi',
'umfla',
'umflă',
'umfle',
'umfli',
'umflu',
'umida',
'umidă',
'umide',
'umidi',
'umido',
'umila',
'umilă',
'umile',
'umili',
'umizi',
'umori',
'umple',
'umpli',
'umplu',
'unaul',
'unchi',
'uncia',
'uncie',
'uncii',
'undai',
'undam',
'undat',
'undau',
'undăm',
'undea',
'undei',
'undez',
'undii',
'undim',
'undit',
'undoi',
'undos',
'undui',
'uneai',
'uneam',
'uneau',
'uneia',
'unele',
'unesc',
'ungar',
'ungea',
'ungem',
'unghi',
'ungur',
'ungvi',
'uniat',
'uniax',
'unica',
'unică',
'unice',
'unici',
'unime',
'unimi',
'unind',
'uniră',
'unire',
'uniri',
'unise',
'uniși',
'unita',
'unită',
'unite',
'uniti',
'uniți',
'unora',
'unsei',
'unsul',
'unșii',
'unșpe',
'untar',
'untos',
'untul',
'unuia',
'urale',
'urano',
'urară',
'urare',
'urase',
'urași',
'urata',
'urată',
'urate',
'urați',
'urări',
'urăsc',
'urând',
'urâră',
'urâre',
'urâri',
'urâse',
'urâși',
'urâta',
'urâtă',
'urâte',
'urâți',
'urban',
'urbea',
'urbei',
'urcai',
'urcam',
'urcat',
'urcau',
'urcăm',
'urcuș',
'urdar',
'urdea',
'urdei',
'urdii',
'urdim',
'urdin',
'urdit',
'urduc',
'urdul',
'uredo',
'ureea',
'ureei',
'ureic',
'uretr',
'ureus',
'ureze',
'urezi',
'urgia',
'urgie',
'urgii',
'uriaș',
'urica',
'urică',
'urice',
'urici',
'urile',
'urina',
'urină',
'urine',
'urini',
'urlai',
'urlam',
'urlat',
'urlau',
'urlăm',
'urlet',
'urloi',
'urlui',
'urlup',
'urmai',
'urmam',
'urmaș',
'urmat',
'urmau',
'urmăm',
'urmei',
'urmez',
'urmii',
'urmul',
'urnea',
'urnei',
'urnii',
'urnim',
'urnit',
'ursac',
'ursar',
'ursea',
'ursei',
'ursii',
'ursim',
'ursin',
'ursit',
'ursoi',
'urson',
'ursul',
'ursuz',
'urșii',
'uruia',
'uruie',
'uruii',
'uruim',
'uruit',
'urzar',
'urzea',
'urzic',
'urzii',
'urzim',
'urzit',
'urzoi',
'uscai',
'uscam',
'uscat',
'uscau',
'uscăm',
'uslaș',
'usnei',
'ustaș',
'usucă',
'usuce',
'usuci',
'ușari',
'ușchi',
'ușeri',
'ușier',
'ușile',
'ușița',
'ușiță',
'ușițe',
'ușori',
'ușuia',
'ușuii',
'ușuim',
'ușuit',
'ușura',
'ușură',
'ușure',
'ușuri',
'ușuța',
'ușuță',
'ușuțe',
'utere',
'utero',
'utila',
'utilă',
'utile',
'utili',
'uvala',
'uvală',
'uvale',
'uveal',
'uveea',
'uveei',
'uviol',
'uvraj',
'uvula',
'uvulă',
'uvule',
'uvulo',
'uxere',
'uzaje',
'uzară',
'uzare',
'uzase',
'uzași',
'uzata',
'uzată',
'uzate',
'uzați',
'uzări',
'uzând',
'uzbec',
'uzeze',
'uzezi',
'uzina',
'uzină',
'uzine',
'uzita',
'uzită',
'uzual',
'uzura',
'uzură',
'uzuri',
'uzurp',
'vacat',
'vacii',
'vacua',
'vacuă',
'vacue',
'vacui',
'vacul',
'vacuu',
'vadea',
'vadra',
'vadră',
'vadul',
'vafei',
'vagal',
'vagii',
'vagil',
'vagin',
'vagon',
'vague',
'vagul',
'vaier',
'vaiet',
'vaită',
'vaite',
'vaiți',
'valah',
'valea',
'valet',
'valeu',
'valex',
'valid',
'valii',
'valiu',
'valma',
'valmă',
'valon',
'valsa',
'valsă',
'valul',
'valva',
'valvă',
'valve',
'valvi',
'vamal',
'vameș',
'vampa',
'vampă',
'vampe',
'vanda',
'vande',
'vanei',
'vanii',
'vanul',
'vapel',
'vapor',
'varan',
'varda',
'varec',
'vareg',
'varga',
'vargă',
'varia',
'varie',
'varii',
'vario',
'variu',
'varsă',
'varta',
'varul',
'varus',
'varva',
'varvă',
'varve',
'varza',
'varză',
'vasal',
'vasca',
'vască',
'vasce',
'vasel',
'vasta',
'vastă',
'vaste',
'vasul',
'vaști',
'vatăm',
'vatei',
'vates',
'vatir',
'vatos',
'vatra',
'vatră',
'vazei',
'văcar',
'vădan',
'vădea',
'vădii',
'vădim',
'vădit',
'văduv',
'văgaș',
'văiag',
'văile',
'văina',
'văină',
'văita',
'văită',
'vălău',
'văleu',
'vălmi',
'vălug',
'vălul',
'vămii',
'vămui',
'văpăi',
'văpsi',
'vărai',
'văram',
'vărar',
'vărat',
'vărau',
'vărăm',
'vărez',
'vărga',
'vărgă',
'vărgi',
'văros',
'vărsa',
'vărsă',
'vărui',
'vărul',
'văsar',
'văsuț',
'vătaf',
'vătaj',
'vătaș',
'vătav',
'vătui',
'văzui',
'văzul',
'văzut',
'vâjâi',
'vâjea',
'vâjia',
'vâjie',
'vâjii',
'vâjim',
'vâjit',
'vâjoi',
'vâjul',
'vâlca',
'vâlcă',
'vâlci',
'vâlfa',
'vâlfă',
'vâlfe',
'vâlva',
'vâlvă',
'vâlve',
'vânai',
'vânam',
'vânat',
'vânau',
'vânăm',
'vânăt',
'vândă',
'vându',
'vânez',
'vânji',
'vânos',
'vânză',
'vârai',
'vâram',
'vârau',
'vârâi',
'vârâm',
'vârât',
'vârca',
'vârcă',
'vârci',
'vârșa',
'vârșă',
'vârșe',
'vârși',
'vâsla',
'vâslă',
'vâsle',
'vâsli',
'vâșca',
'vâșcă',
'vâște',
'vâști',
'vecea',
'veceu',
'veche',
'vechi',
'vecia',
'vecie',
'vecii',
'vecin',
'vecto',
'vecui',
'vedda',
'vedde',
'vedea',
'vedei',
'vedem',
'vedic',
'vedre',
'vegăi',
'veghe',
'veghi',
'velar',
'velea',
'velei',
'velic',
'velii',
'velim',
'velin',
'velit',
'velum',
'velur',
'venal',
'venea',
'venei',
'venet',
'venia',
'venie',
'venii',
'venim',
'venin',
'venit',
'venos',
'venus',
'verbe',
'verde',
'verei',
'verga',
'vergă',
'vergi',
'veric',
'verif',
'verii',
'verin',
'vermi',
'veros',
'verse',
'verso',
'verși',
'verui',
'verva',
'vervă',
'verve',
'verze',
'verzi',
'vesel',
'vesta',
'vestă',
'veste',
'vesti',
'veșca',
'veșcă',
'vești',
'vetre',
'vetri',
'vexai',
'vexam',
'vexat',
'vexau',
'vexăm',
'vexez',
'vexil',
'viară',
'viase',
'viași',
'viața',
'viață',
'viați',
'viază',
'vibra',
'vibră',
'vibro',
'vicar',
'vicia',
'vicie',
'vicii',
'viciu',
'vidai',
'vidaj',
'vidam',
'vidat',
'vidau',
'vidăm',
'videi',
'video',
'videz',
'vidma',
'vidmă',
'vidme',
'vidra',
'vidră',
'vidre',
'vidul',
'viela',
'vielă',
'viele',
'viere',
'vieri',
'viers',
'viesc',
'vieți',
'vieze',
'viezi',
'vifel',
'vifor',
'vigan',
'vigia',
'vigie',
'vigii',
'vigil',
'vigul',
'viile',
'viind',
'vilei',
'vilii',
'vilit',
'vilos',
'vilul',
'vinar',
'vinaț',
'vinci',
'vinde',
'vinei',
'vinia',
'vinie',
'vinii',
'vinil',
'viniu',
'vinos',
'vinul',
'vinuț',
'vinzi',
'vioda',
'viodă',
'viode',
'vioii',
'viola',
'violă',
'viole',
'viori',
'vipia',
'vipie',
'vipii',
'vipla',
'viplă',
'viple',
'vipul',
'virai',
'viraj',
'viral',
'viram',
'viran',
'virat',
'virau',
'virăm',
'virez',
'virga',
'virid',
'viril',
'virul',
'virus',
'visai',
'visam',
'visat',
'visau',
'visăm',
'visco',
'visez',
'visul',
'vișin',
'vitae',
'vital',
'vitei',
'vitro',
'vițea',
'viței',
'vițel',
'viții',
'vițiu',
'vițos',
'viuța',
'viuță',
'viuțe',
'viuți',
'vivat',
'vizai',
'vizam',
'vizat',
'vizau',
'vizăm',
'vizei',
'vizez',
'vizii',
'vizir',
'vizon',
'vizor',
'vlaga',
'vlagă',
'vlaha',
'vlahă',
'vlahe',
'vlahi',
'voala',
'voală',
'vocal',
'vocea',
'vocii',
'vodca',
'vodcă',
'vodci',
'voder',
'vogăi',
'voiai',
'voiaj',
'voiam',
'voiau',
'voile',
'voind',
'voios',
'voiră',
'voire',
'voiri',
'voise',
'voiși',
'voita',
'voită',
'voite',
'voiți',
'voiul',
'volan',
'volar',
'volei',
'volet',
'voleu',
'voloc',
'volog',
'volsc',
'volta',
'voltă',
'volte',
'volți',
'volum',
'volva',
'volvă',
'volve',
'vomai',
'vomam',
'vomat',
'vomau',
'vomăm',
'vomei',
'vomer',
'vomez',
'vomic',
'vomit',
'vopsi',
'vorba',
'vorbă',
'vorbe',
'vorbi',
'voști',
'votai',
'votam',
'votat',
'votau',
'votăm',
'votca',
'votcă',
'votci',
'votez',
'votiv',
'votri',
'votru',
'votul',
'votum',
'vozai',
'vozam',
'vozau',
'vozâi',
'vozâm',
'vozât',
'vrace',
'vraci',
'vraja',
'vrajă',
'vrana',
'vrană',
'vrane',
'vrați',
'vrăji',
'vrând',
'vreai',
'vream',
'vreau',
'vreie',
'vreji',
'vreme',
'vremi',
'vrere',
'vreri',
'vreți',
'vreun',
'vriei',
'vrila',
'vrilă',
'vrile',
'vroia',
'vroii',
'vroim',
'vroit',
'vruna',
'vrură',
'vruse',
'vruși',
'vruta',
'vrută',
'vrute',
'vruți',
'vtori',
'vuiau',
'vuiet',
'vuind',
'vuiră',
'vuire',
'vuiri',
'vuise',
'vulpe',
'vulpi',
'vulva',
'vulvă',
'vulve',
'vulvo',
'vurta',
'vurtă',
'vurte',
'vutca',
'vutcă',
'vutci',
'vutei',
'vuvar',
'vuvei',
'vuvui',
'walon',
'wații',
'weber',
'whigi',
'widia',
'widii',
'wilow',
'wolof',
'wonii',
'wonul',
'xanto',
'xenia',
'xenie',
'xenii',
'xenon',
'xeres',
'xeric',
'xerom',
'xerox',
'xifia',
'xifie',
'xifii',
'xifos',
'xilan',
'xilem',
'xilen',
'xilit',
'xilol',
'xilon',
'xisma',
'xismă',
'xisme',
'yalei',
'yarzi',
'yenii',
'yenul',
'yetii',
'yogin',
'ytrit',
'ytriu',
'yuani',
'yucca',
'yunga',
'yuppi',
'zabet',
'zabra',
'zabră',
'zabre',
'zadar',
'zadei',
'zadia',
'zadie',
'zadii',
'zahar',
'zahăr',
'zaica',
'zaică',
'zaici',
'zaifa',
'zaifă',
'zaife',
'zaifi',
'zaimf',
'zairi',
'zalei',
'zamac',
'zambo',
'zamca',
'zamcă',
'zapai',
'zapaj',
'zapam',
'zapat',
'zapau',
'zapăm',
'zapez',
'zapis',
'zaraf',
'zarea',
'zarei',
'zarif',
'zarpa',
'zarul',
'zarva',
'zarvă',
'zarve',
'zațul',
'zăbun',
'zăcaș',
'zăcea',
'zăcem',
'zăcui',
'zăcut',
'zădar',
'zăduf',
'zăduh',
'zăgan',
'zăgaș',
'zăgaz',
'zăhăi',
'zălar',
'zălog',
'zălud',
'zălug',
'zămci',
'zămii',
'zănat',
'zăpăi',
'zăpor',
'zăpsi',
'zăpuc',
'zărar',
'zărea',
'zării',
'zărim',
'zărit',
'zăsti',
'zăton',
'zăuit',
'zăvod',
'zăvoi',
'zăvor',
'zâmbi',
'zânei',
'zârna',
'zârnă',
'zârne',
'zâzâi',
'zbanț',
'zbată',
'zbate',
'zbați',
'zbătu',
'zbânț',
'zbârn',
'zbeng',
'zbice',
'zbici',
'zbier',
'zbilț',
'zbiri',
'zbori',
'zbura',
'zbură',
'zburd',
'zeama',
'zeamă',
'zeaua',
'zebra',
'zebră',
'zebre',
'zebul',
'zecea',
'zecer',
'zeche',
'zechi',
'zeele',
'zefir',
'zeghe',
'zeghi',
'zeiei',
'zeina',
'zeină',
'zeine',
'zeița',
'zeiță',
'zeițe',
'zelar',
'zelei',
'zelos',
'zelot',
'zelul',
'zemii',
'zemos',
'zemui',
'zenda',
'zendă',
'zende',
'zenit',
'zenul',
'zenzi',
'zeppa',
'zeppă',
'zeppe',
'zerar',
'zerea',
'zerit',
'zeros',
'zerul',
'zețaj',
'zețar',
'zețui',
'zgăul',
'zgăur',
'zgâia',
'zgâii',
'zgâim',
'zgâit',
'zguli',
'zgura',
'zgură',
'zguri',
'ziare',
'zicaș',
'zicea',
'zicem',
'zidar',
'zidea',
'zidii',
'zidim',
'zidit',
'zidui',
'zidul',
'zigot',
'zilaș',
'zilei',
'ziler',
'zilos',
'zimța',
'zimță',
'zimți',
'zinca',
'zincă',
'zisei',
'zisul',
'zișii',
'zitum',
'ziuca',
'ziucă',
'ziuci',
'zlace',
'zlaci',
'zlați',
'zloți',
'zmeie',
'zmeii',
'zmeoi',
'zmeul',
'zmeur',
'zoaia',
'zoaie',
'zoala',
'zoală',
'zoale',
'zoana',
'zoană',
'zoane',
'zoarh',
'zoava',
'zoavă',
'zoave',
'zobea',
'zobii',
'zobim',
'zobit',
'zobon',
'zobul',
'zodia',
'zodie',
'zodii',
'zoile',
'zoili',
'zoios',
'zolea',
'zolga',
'zolgă',
'zolge',
'zolgi',
'zolii',
'zolim',
'zolit',
'zombi',
'zonaj',
'zonal',
'zonar',
'zonei',
'zooid',
'zooni',
'zooul',
'zooza',
'zooză',
'zooze',
'zopăi',
'zoppa',
'zorea',
'zorei',
'zorel',
'zorii',
'zoril',
'zorim',
'zorit',
'zorul',
'zovon',
'zuavi',
'zuful',
'zulia',
'zulie',
'zulii',
'zuluf',
'zulum',
'zulus',
'zuluș',
'zupăi',
'zupui',
'zurăi',
'zurba',
'zurbă',
'zurbe',
'zurrr',
'zurui',
'zuzăi',
'zuzet',
'zuzui',
'zvânt',
'zvârl',
'zvârr',
'zvelt',
'zvoni' 
]

