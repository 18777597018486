export const WORDLE_TITLE = 'ROrdle.ro - wordle românesc'
export const WIN_MESSAGES = ['Bravo', 'Felicitări']
export const GAME_COPIED_MESSAGE = 'Joc copiat'
export const ABOUT_GAME_MESSAGE = 'Despre'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Prea puține litere'
export const WORD_NOT_FOUND_MESSAGE = 'Nu am găsit cuvântul'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Cuvântul corect este  ${solution}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Șterge'
export const STATISTICS_TITLE = 'Statistici'
export const GUESS_DISTRIBUTION_TEXT = 'Distribuția cuvintelor ghicite'
export const NEW_WORD_TEXT = 'Cuvânt nou în '
export const SHARE_TEXT = 'Share'
export const TOTAL_TRIES_TEXT = 'Încercări totale'
export const SUCCESS_RATE_TEXT = 'Rata de succes'
export const CURRENT_STREAK_TEXT = 'Zile de când tot câștigi :)'
export const BEST_STREAK_TEXT = 'Record personal de zile '
