import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Despre ROrdle.ro" isOpen={isOpen} handleClose={handleClose}>

      <p className="text-sm text-gray-500 dark:text-gray-300">
        Acest joc este o clonă în limba română a jocului Wordle. Puteți ghici un singur cuvânt în fiecare zi. <br/>
	Pe {' '}
        <a
          href="https://rordle.ro/nonstop/"
          className="underline font-bold"
        >
          https://rordle.ro/nonstop/
        </a>{' '}
        puteți juca nonstop, fără limita de un cuvânt pe zi.


</p>
   <p className="text-sm text-gray-500 dark:text-gray-300">
        Contact: {' '}
        <a
          href="https://twitter.com/rordle"
          className="underline font-bold"
        >
          Twitter
        </a>{' '}
        ,  {' '}
        <a
          href="https://www.facebook.com/rordle.ro"
          className="underline font-bold"
        >
          Facebook
        </a>{' '}

        </p>

   <p className="text-sm text-gray-500 dark:text-gray-300">
Baza de date folosită: {' '}
        <a
          href="https://dexonline.ro/scrabble"
          className="underline font-bold"
        >
          dexonline.ro
        </a>{' '} 
        </p>



    </BaseModal>
  )
}

