import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Cum se joacă ROrdle" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Ghiciți cuvântul din 6 încercări. După fiecare încercare culoarea literelor se schimbă și primiți unele indicii. <br/>
	Puteți ghici un cuvânt pe zi. <br/>
Pe {' '}
        <a
          href="https://rordle.ro/nonstop/"
          className="underline font-bold"
        >
          https://rordle.ro/nonstop/
        </a>{' '}
        puteți juca nonstop, fără limita de un cuvânt pe zi.

      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="C" status="correct" />
        <Cell value="Ă" />
        <Cell value="R" />
        <Cell value="U" />
        <Cell value="Ț" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Litera C există în cuvânt și ați ghicit și poziția corectă.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" />
        <Cell value="I" />
        <Cell value="L" status="present" />
        <Cell value="O" />
        <Cell value="T" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Litera L există în cuvânt dar nu în această poziție.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="C" />
        <Cell value="O" />
        <Cell value="P" />
        <Cell value="I" status="absent" />
        <Cell value="L" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Litera I nu există deloc în cuvânt.
      </p>
    </BaseModal>
  )
}

