export const WORDS = [
'gliei',
'molâu',
'radia',
'ordin',
'jeton',
'lepăd',
'dulău',
'suine',
'caldă',
'trudă',
'vreme',
'liant',
'turci',
'plită',
'căram',
'boită',
'angro',
'urzic',
'palma',
'aripa',
'beată',
'adusă',
'pleca',
'griji',
'țiplă',
'șomat',
'opacă',
'sufle',
'dresa',
'glonț',
'spumă',
'amibe',
'miros',
'mingi',
'suire',
'iedul',
'corbi',
'multă',
'clase',
'haios',
'gâlmă',
'vidat',
'priză',
'buric',
'văduv',
'cuarț',
'gardă',
'codru',
'album',
'tacit',
'canar',
'sosii',
'alura',
'eleve',
'gofră',
'zonal',
'stern',
'ținti',
'negoț',
'pufăi',
'manșă',
'atare',
'școli',
'croit',
'grele',
'vlaga',
'urare',
'ciont',
'piane',
'forma'
]

